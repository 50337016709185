import React from 'react';
import './Overview.css';

const Overview: React.FC = () => {
    return (
        <div className='Overview'>
            <h1 className='Overview-Title'>Any payor. Any specialty. Any call.</h1>
            <h2 className='Overview-Subtitle'>We handle your:</h2>
            <ul className='Overview-Features'>
                <li><h3>Benefit Verifications</h3></li>
                <li><h3>Prior Authorizations</h3></li>
                <li><h3>Claim Status</h3></li>
            </ul>
            <h2 className='Overview-Subtitle'>for Medical, Dental, Behavioral Health, and Pharmacy.</h2>
        </div>
    )
}

export default Overview;
