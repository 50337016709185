import { Column } from "react-table";
import React from 'react'

const IDLE_MS = 1000 * 60 * 5;
export const DASHBOARD_UPDATE_POLLING_FREQUENCY_MS = 1000 * 10 * 60;


export class Option {
    label: string;
    value: string | boolean;
    constructor(label: string, value: string) {
        this.label = label;
        this.value = value;
    }
}

export const INSURANCES_PROD_MEDICAL_AND_DENTAL: Array<Option> = [
    { label: "Insurance", value: '' },
    { label: "Aetna", value: "AETNA" },
    { label: "Anthem Blue Cross of California", value: "ANTHEM_BLUE_CROSS_CA" },
    { label: "Blue Cross Blue Shield (General)", value: "BLUE_CROSS_BLUE_SHIELD" },
    { label: "BCBS FEP (Write in phone number below)", value: "BLUE_CROSS_BLUE_SHIELD_FEP" },
    { label: "Blue Shield of California", value: "BLUE_SHIELD_CA" },
    { label: "Cigna", value: "CIGNA" },
    { label: "Delta Dental of California", value: "DELTA_DENTAL_CA" },
    { label: "Delta Dental of DC", value: "DELTA_DENTAL_DC" },
    { label: "Delta Dental of Delaware", value: "DELTA_DENTAL_DE" },
    { label: "Delta Dental of Maryland", value: "DELTA_DENTAL_MD" },
    { label: "Delta Dental of New York", value: "DELTA_DENTAL_NY" },
    { label: "Delta Dental of Pennsylvania", value: "DELTA_DENTAL_PA" },
    { label: "Delta Dental of West Virginia", value: "DELTA_DENTAL_WV" },
    { label: "Deseret Mutual Benefit Administrators (DMBA)", value: "DESERET_MUTUAL_BENEFIT_ADMINISTRATORS" },
    { label: "Emblem", value: "EMBLEM" },
    { label: "Empire", value: "EMPIRE" },
    { label: "Guardian", value: "GUARDIAN" },
    { label: "Healthplex", value: "HEALTHPLEX" },
    { label: "Humana", value: "HUMANA" },
    { label: "MetLife", value: "METLIFE" },
    { label: "Seledent", value: "SELEDENT" },
    { label: "United Healthcare", value: "UNITED_HEALTHCARE" },
    { label: "United Concordia", value: "UNITED_CONCORDIA" },
    { label: "Other (Write in insurance name and phone number)", value: "OTHER" }
]


// Insurances for production dental users.
export const INSURANCES_PROD_DENTAL: Array<Option> = [
    { label: "Insurance", value: '' },
    { label: "Aetna", value: "AETNA" },
    { label: "Anthem Blue Cross of California", value: "ANTHEM_BLUE_CROSS_CA" },
    { label: "Blue Shield of California", value: "BLUE_SHIELD_CA" },
    { label: "BCBS FEP (Write in phone number below)", value: "BLUE_CROSS_BLUE_SHIELD_FEP" },
    { label: "Cigna", value: "CIGNA" },
    { label: "DDS", value: "DDS" },
    { label: "Delta Dental of California", value: "DELTA_DENTAL_CA" },
    { label: "Delta Dental of DC", value: "DELTA_DENTAL_DC" },
    { label: "Delta Dental of Delaware", value: "DELTA_DENTAL_DE" },
    { label: "Delta Dental of Maryland", value: "DELTA_DENTAL_MD" },
    { label: "Delta Dental of New York", value: "DELTA_DENTAL_NY" },
    { label: "Delta Dental of Pennsylvania", value: "DELTA_DENTAL_PA" },
    { label: "Delta Dental of West Virginia", value: "DELTA_DENTAL_WV" },
    { label: "EmblemHealth", value: "EMBLEM_HEALTH" },
    { label: "Empire Blue Cross Blue Shield", value: "EMPIRE_BLUE_CROSS_BLUE_SHIELD" },
    { label: "Guardian", value: "GUARDIAN" },
    { label: "Healthplex", value: "HEALTHPLEX" },
    { label: "MetLife", value: "METLIFE" },
    { label: "Seledent", value: "SELEDENT" },
    { label: "United Healthcare", value: "UNITED_HEALTHCARE" },
    { label: "United Concordia", value: "UNITED_CONCORDIA" },
    { label: "Other (Write in insurance name and phone number)", value: "OTHER" }
];

// Insurances for production medical users.
export const INSURANCES_PROD_MEDICAL: Array<Option> = [
    { label: "Insurance", value: '' },
    { label: "Aetna", value: "AETNA" },
    { label: "Anthem Blue Cross of California", value: "ANTHEM_BLUE_CROSS_CA" },
    { label: "Anthem Blue Cross Blue Shield of Georgia", value: "BLUE_CROSS_BLUE_SHIELD_GA_ANTHEM" },
    { label: "Blue Cross Blue Shield (General)", value: "BLUE_CROSS_BLUE_SHIELD" },
    { label: "BCBS FEP (Write in phone number below)", value: "BLUE_CROSS_BLUE_SHIELD_FEP" },
    { label: "Blue Cross Blue Shield of Illinois", value: "BLUE_CROSS_BLUE_SHIELD_IL" },
    { label: "Blue Cross Blue Shield of Montana", value: "BLUE_CROSS_BLUE_SHIELD_MT" },
    { label: "Blue Cross Blue Shield of New Mexico", value: "BLUE_CROSS_BLUE_SHIELD_NM" },
    { label: "Blue Cross Blue Shield of Oklahoma", value: "BLUE_CROSS_BLUE_SHIELD_OK" },
    { label: "Blue Cross Blue Shield of Texas", value: "BLUE_CROSS_BLUE_SHIELD_TX" },
    { label: "Blue Shield of California", value: "BLUE_SHIELD_CA" },
    { label: "Cigna", value: "CIGNA" },
    { label: "EmblemHealth", value: "EMBLEM_HEALTH" },
    { label: "Highmark Blue Cross Blue Shield of Delaware", value: "BLUE_CROSS_BLUE_SHIELD_DE_HIGHMARK" },
    { label: "Highmark Blue Cross Blue Shield of New York", value: "BLUE_CROSS_BLUE_SHIELD_NY_HIGHMARK" },
    { label: "Highmark Blue Cross Blue Shield of West Virgnia", value: "BLUE_CROSS_BLUE_SHIELD_WV_HIGHMARK" },
    { label: "Horizon Blue Cross Blue Shield of New Jersey", value: "BLUE_CROSS_BLUE_SHIELD_NJ_HORIZON" },
    { label: "Humana", value: "HUMANA" },
    { label: "Priority Health", value: "PRIORITY_HEALTH" },
    { label: "United Healthcare", value: "UNITED_HEALTHCARE" },
    { label: "Other (Write in insurance name and phone number)", value: "OTHER" }
];

export const INSURANCES_PROD_MENTAL_HEALTH: Array<Option> = [
    { label: "Insurance", value: '' },
    { label: "Aetna", value: "AETNA" },
    { label: "Anthem Blue Cross of California", value: "ANTHEM_BLUE_CROSS_CA" },
    { label: "Anthem Blue Cross Blue Shield of Georgia", value: "BLUE_CROSS_BLUE_SHIELD_GA_ANTHEM" },
    { label: "Blue Cross Blue Shield (General)", value: "BLUE_CROSS_BLUE_SHIELD" },
    { label: "BCBS FEP (Write in phone number below)", value: "BLUE_CROSS_BLUE_SHIELD_FEP" },
    { label: "Blue Cross Blue Shield of Illinois", value: "BLUE_CROSS_BLUE_SHIELD_IL" },
    { label: "Blue Cross Blue Shield of Montana", value: "BLUE_CROSS_BLUE_SHIELD_MT" },
    { label: "Blue Cross Blue Shield of New Mexico", value: "BLUE_CROSS_BLUE_SHIELD_NM" },
    { label: "Blue Cross Blue Shield of Oklahoma", value: "BLUE_CROSS_BLUE_SHIELD_OK" },
    { label: "Blue Cross Blue Shield of Texas", value: "BLUE_CROSS_BLUE_SHIELD_TX" },
    { label: "Blue Cross Complete of Michigan", value: "BLUE_CROSS_COMPLETE_MI" },
    { label: "Blue Shield of California", value: "BLUE_SHIELD_CA" },
    { label: "Cigna", value: "CIGNA" },
    { label: "Empire Blue Cross Blue Shield", value: "EMPIRE_BLUE_CROSS_BLUE_SHIELD" },
    { label: "Highmark Blue Cross Blue Shield of Delaware", value: "BLUE_CROSS_BLUE_SHIELD_DE_HIGHMARK" },
    { label: "Highmark Blue Cross Blue Shield of New York", value: "BLUE_CROSS_BLUE_SHIELD_NY_HIGHMARK" },
    { label: "Highmark Blue Cross Blue Shield of West Virgnia", value: "BLUE_CROSS_BLUE_SHIELD_WV_HIGHMARK" },
    { label: "Horizon Blue Cross Blue Shield of New Jersey", value: "BLUE_CROSS_BLUE_SHIELD_NJ_HORIZON" },
    { label: "Humana", value: "HUMANA" },
    { label: "Independence Blue Cross (Pennsylvania)", value: "INDEPENDENCE_BLUE_CROSS_PA" },
    { label: "Magellan", value: "MAGELLAN" },
    { label: "Optum", value: "OPTUM" },
    { label: "Quest Behavioral Health", value: "QUEST_BEHAVIORAL_HEALTH" },
    { label: "United Healthcare", value: "UNITED_HEALTHCARE" },
    { label: "University of Pittsburgh Medical Center (UPMC)", value: "UNIVERSITY_OF_PITTSBURGH_MEDICAL_CENTER" },
    { label: "Other (Write in insurance name and phone number)", value: "OTHER" }
];

// Insurances for debug users
export const INSURANCES_DEBUG: Array<Option> = INSURANCES_PROD_DENTAL.concat(INSURANCES_PROD_MEDICAL).concat(INSURANCES_PROD_MENTAL_HEALTH);

export const NETWORK: Array<Option> = [
    { label: "Select Coverage", value: '' },
    { label: "In Network", value: true },
    { label: "Out of Network", value: false },
];

export const NETWORK_OPTIONAL: Array<Option> = [
    { label: "Select Coverage", value: '' },
    { label: "In Network", value: true },
    { label: "Out of Network", value: false },
    { label: "Confirm Network Status", value: "null" },
];

export const CODE_COVERAGE: Array<Option> = [
    { label: "Select Coverage", value: '' },
    { label: "Covered", value: true },
    { label: "Not Covered", value: false },
    { label: "Not Covered (more info)", value: true },
];

export const URGENCY: Array<Option> = [
    { label: "Select Urgency", value: '' },
    { label: "Urgent (~3 hours)", value: 'URGENT' },
    { label: "Normal (~24 hours)", value: 'NONURGENT' },
];

export const REQUEST_TYPES_DENTAL: Array<Option> = [
    { label: "Select Type", value: '' },
    { label: "Benefits (IV) Form", value: 'ELIGIBILITY_SEARCH' },
    { label: "Prior Auth Status", value: 'PRIOR_AUTH_STATUS' },
    { label: "Claims Status", value: 'CLAIMS' },
];

export const REQUEST_TYPES_MEDICAL: Array<Option> = [
    { label: "Select Type", value: '' },
    { label: "Benefits Form", value: 'ELIGIBILITY_SEARCH' },
    { label: "Prior Auth Status", value: 'PRIOR_AUTH_STATUS' },
    { label: "Claims Status", value: 'CLAIMS' },
];

export const INQUIRY_TYPE_TO_REQUEST_TYPE: Record<string, string> = {
    'ELIGIBILITY_SEARCH': 'Benefits Form',
    'CLAIMS': 'Claims Status',
    'PRIOR_AUTH_STATUS': 'Prior Auth Status',
}

export const ELIGIBILITY_SEARCH_QUERIES_MEDICAL_AND_DENTAL: Array<Option> = [
    { label: "Dental - Basic", value: 'ORAL_SURGERY_BASIC' },
    { label: "Dental - Exraction, Bone Graft, Implants", value: 'ORAL_SURGERY_EXTRACTION_BONE_GRAFT_IMPLANTS' },
    { label: "Dental - Orthodontic Uncovering", value: 'ORAL_SURGERY_ORTHODONTIC_UNCOVERING' },
    { label: "Dental - Wisdom Teeth", value: 'ORAL_SURGERY_WISDOM_TEETH' },
    { label: "Dental - TMJ", value: 'ORAL_SURGERY_TMJ' },
    { label: "Medical - Pathology", value: 'ORAL_SURGERY_PATHOLOGY' },
    { label: "Medical - Orthognathic", value: 'ORAL_SURGERY_ORTHOGNATHIC' },
]

export const ELIGIBILITY_SEARCH_QUERIES_DENTAL: Array<Option> = [
    { label: "Standard (Returning Patient)", value: "STANDARD_DENTAL" },
    { label: "Comprehensive (New Patient)", value: "COMPREHENSIVE_DENTAL" },
    { label: "Fee Schedule Name", value: "FEE_SCHEDULE" },
    { label: "Treatment History", value: "TREATMENT_HISTORY" },
    { label: "Full (Legacy)", value: "FULL_DENTAL_BENEFITS" },
    { label: "Partial (Legacy)", value: "PARTIAL_DENTAL_BENEFITS" }
]

export const ELIGIBILITY_SEARCH_QUERIES_MEDICAL: Array<Option> = [
    { label: "Is pulmonary rehab covered?", value: 'PULMONARY_REHAB_COVERAGE' },
    { label: "Which radiation oncology codes are covered?", value: 'RADIATION_COVERAGE' },
    { label: "Are RTM and PCM codes covered?", value: 'RTM_PCM_COVERAGE' },
    { label: "What is the chiropractic and physical therapy coverage?", value: 'CHIROPRACTIC_AND_PHYSICAL_THERAPY_COVERAGE' },
    { label: "What is coverage for chronic care management?", value: 'CHRONIC_CARE_MANAGEMENT' },
    { label: "What is the coverage for cardiac chronic care management?", value: 'CARDIAC_CHRONIC_CARE_MANAGEMENT' }
]


export const ELIGIBILITY_SEARCH_QUERIES_MENTAL_HEALTH: Array<Option> = [
    { label: "What is the psychotherapy coverage?", value: 'PSYCHOTHERAPY' },
    { label: "What is the ABA coverage?", value: 'ABA_COVERAGE' },
    { label: "What is the behavioral health rehab coverage?", value: 'MENTAL_HEALTH_REHAB_COVERAGE' },
    { label: "What is the psychiatric evaluation coverage?", value: 'PSYCHIATRIC_DIAGNOSIS' },
    { label: "What is the general office visit coverage?", value: 'OFFICE_VISIT' },
    { label: "What is the psychological and neuropsychological testing coverage?", value: 'PSYCHOLOGICAL_TESTING' },
]

export const TEAM_BASED_INSURANCES: Record<string, Array<Option>> = {
    // (StarBrite)
    "1cf0cdb3-b41b-4a61-ab0f-ad153bda280c": INSURANCES_PROD_DENTAL.concat([
        { label: "Other (Write in insurance name and phone number)", value: "OTHER" }
    ])
}

export const EMAILS_BULK_CLAIMS_CSV_ENABLED: Array<string> = [
    'michele@thesmilist.com',
    'nona@thesmilist.com',
    'phil@thesmilist.com',
    'mayank@withguardian.ai',
    'pranav@withguardian.ai'
]


// Dumping ground for al the others that we want to access in debug.
export const ELIGIBILITY_SEARCH_QUERIES_OTHER: Array<Option> = [
    { label: "Customized Periodontics Form", value: 'PERIO_SURGERY' },
    { label: "Customized Orthodontics Form", value: 'ORTHODONTICS_COVERAGE' },
    { label: "Customized Airpay Form", value: 'AIRPAY' },
    { label: "Remicade", value: 'REMICADE' },
    { label: "Avsola", value: 'AVSOLA' }
]

export const ELIGIBILITY_SEARCH_QUERIES_ALL: Array<Option> = ELIGIBILITY_SEARCH_QUERIES_DENTAL.concat(ELIGIBILITY_SEARCH_QUERIES_MEDICAL).concat(ELIGIBILITY_SEARCH_QUERIES_MENTAL_HEALTH).concat(ELIGIBILITY_SEARCH_QUERIES_OTHER).concat(ELIGIBILITY_SEARCH_QUERIES_MEDICAL_AND_DENTAL);

export const TEAM_BASED_ELIGIBILITY_SEARCH_QUERIES: Record<string, Array<Option>> = {
    // Debbie (Smilist) Team ID
    "07f12e16-df85-47a8-a29c-4b24be0e3c03": [
        { label: "Customized Periodontics Form", value: 'PERIO_SURGERY' },
        { label: "Customized Orthodontics Form", value: 'ORTHODONTICS_COVERAGE' },
        { label: "Standard IV (Returning Patient)", value: 'STANDARD_DENTAL' },
        { label: "Comprehensive IV (New Patient)", value: 'COMPREHENSIVE_DENTAL' }
    ],
    // Dan (Smylen) Team ID
    "5307c8ed-2e0b-4e3d-92cf-3e096d0ef4cb": [
        { label: "Customized Airpay Form", value: 'AIRPAY' },
        { label: "Standard IV (Returning Patient)", value: 'STANDARD_DENTAL' },
        { label: "Comprehensive IV (New Patient)", value: 'COMPREHENSIVE_DENTAL' }
    ],
    // Will (Coral) Team ID
    "ab3f42d2-3e09-4683-b237-87d26e7c544e": [
        { label: "Remicade", value: 'REMICADE' },
        { label: "Avsola", value: 'AVSOLA' }
    ]
}


export const DEBUG_EMAILS: Array<string> = [
    'alan@healthharbor.co',
    'nathan@healthharbor.co',
    'louise@healthharbor.co'
];

export const STATUS: Record<string, string> = {
    'UNKNOWN': 'Unknown',
    'PENDING': 'Scheduled',
    'POSTPONED': 'Scheduled',
    'IN_PROGRESS': 'Calling',
    'SUCCESS': 'Done',
    'UNSUCCESSFUL': 'Incomplete',
    'PARTIAL_SUCCESS': 'Calling',
    'PENDING_PROCESSING': 'Calling',
    'PENDING_REVIEW': 'Calling',
    'PENDING_MANUAL_CALL': 'Calling',
    'INVALID': 'Invalid',
    'CANCELLED': 'Cancelled',
};

export const SPECIALTY_OPTIONS: Array<Option> = [
    { label: "Select an Option", value: '' },
    { label: "Dental", value: "DENTAL" },
    { label: "Medical", value: "MEDICAL" },
    { label: "Medical & Dental", value: "MEDICAL_AND_DENTAL" },
    { label: "Mental Health", value: "MENTAL_HEALTH" }];

export const ONBOARDING_OPTIONS: Array<string> = [
    "Practice Info",
    "ID Numbers",
];

// Columns configuration
export const COLUMNS_CALL_DETAILS: Array<Column> = [
    { Header: 'Representative', accessor: 'representative_name' },
    { Header: 'Reference Number', accessor: 'reference_number' },
];

// Columns configuration
export const COLUMNS_MAXIMUMS: Array<Column> = [
    { Header: 'Max Deductible', accessor: 'max_deductible', Cell: ({ value }) => "$" + value },
    { Header: 'Deductible Met', accessor: 'deductible_used', Cell: ({ value }) => "$" + value },
    { Header: 'Out of Pocket Max', accessor: 'max_out_of_pocket', Cell: ({ value }) => "$" + value },
    { Header: 'Out of Pocket Met', accessor: 'out_of_pocket_used', Cell: ({ value }) => "$" + value },
];

// Columns configuration
export const COLUMNS_PRIOR_AUTH: Array<Column> = [
    { Header: 'Billed To Name', accessor: 'billed_to_name' },
    { Header: 'Phone Number', accessor: 'phone_number' },
    { Header: 'Address', accessor: 'address' },
];

const checkIfNull = (initial: string, final: string, optionalText = false, boolField = false) => {
    if (initial === null) {
        return " "
    }
    if (initial === "N/A") {
        if (optionalText) {
            return (
                <div className="centered">Not covered</div>
            );
        }
        return (
            <div className="line" />
        );
    }
    if (initial === "Unknown") {
        return "Unknown";
    }
    if (boolField) {
        return initial ? <div className="true-cell" /> : <div className="false-cell" />;
    }
    return final;
}

// Columns configuration
export const COLUMNS_CPT: Array<Column> = [
    { Header: '', accessor: 'code', width: 70 },
    { Header: 'Prior Auth Required', accessor: 'prior_auth_required', width: 110, Cell: ({ value }) => checkIfNull(value, value, /*optionalText=*/false, /*boolField=*/true) },
    { Header: 'Deductible Does Not Apply', accessor: 'is_deductible_waived', width: 110, Cell: ({ value }) => checkIfNull(value, value, /*optionalText=*/false, /*boolField=*/true) },
    { Header: 'Copay', accessor: 'copay_amount', width: 110, Cell: ({ value }) => checkIfNull(value, "$" + value) },
    { Header: 'Coinsurance', accessor: 'coinsurance_percentage', width: 110, Cell: ({ value }) => checkIfNull(value, value + "%", true) },
    { Header: 'Frequency Limitations', accessor: 'frequency_limitations', width: 230, Cell: ({ value }) => checkIfNull(value, value) },
    { Header: 'More Info', accessor: 'more_info', Cell: ({ value }) => checkIfNull(value, value) },
];


export const ORIGINAL_INQUIRY = [
    { key: 'practice_name', value: 'Practice', options: null },
    { key: 'provider_name', value: 'Provider', options: null },
    { key: 'insurance', value: 'Insurance', options: null },
    { key: 'insurance_in_network', value: 'Network', options: { true: "In Network", false: "Out of Network", null: "Unknown if in/out of network" } },
    { key: 'desired_completion_date', value: 'Required Completion Date', options: null },
    { key: 'patient_dob', value: 'DOB', options: null },
    { key: 'patient_member_id', value: 'Member ID', options: null },
    { key: 'patient_group_number', value: 'Group #', options: null },
];

export default IDLE_MS;

export const PLACE_OF_SERVICE_DOCTOR_OFFICE = 'DOCTORS_OFFICE';
export const PLACE_OF_SERVICE_PHARMACY = 'PHARMACY';
export const PLACE_OF_SERVICE_TELEHEALTH = 'TELEHEALTH';
export const PLACE_OF_SERVICE_OUTPATIENT_HOSPITAL = 'OUTPATIENT_HOSPITAL';
export const PLACE_OF_SERVICE_INPATIENT_HOSPITAL = 'INPATIENT_HOSPITAL';
export const PLACE_OF_SERVICE_AMBULATORY_SURGICAL_CENTER = 'AMBULATORY_SURGICAL_CENTER';

export const PLACE_OF_SERVICE_OPTIONS: Array<Option> = [
    { label: "Select Place of Service", value: '' },
    { label: "Doctor's Office", value: PLACE_OF_SERVICE_DOCTOR_OFFICE },
    { label: "Telehealth", value: PLACE_OF_SERVICE_TELEHEALTH },
    { label: "Outpatient Hospital", value: PLACE_OF_SERVICE_OUTPATIENT_HOSPITAL },
    { label: "Inpatient Hospital", value: PLACE_OF_SERVICE_INPATIENT_HOSPITAL },
    { label: "Ambulatory Surgical Center", value: PLACE_OF_SERVICE_AMBULATORY_SURGICAL_CENTER },
    { label: "Pharmacy", value: PLACE_OF_SERVICE_PHARMACY },
];

export const SPECIALIST_OPTIONS: Array<Option> = [
    { label: "PCP or Specialist?", value: '' },
    { label: "Primary Care Physician", value: false },
    { label: "Specialist", value: true },
];
