import React from 'react';
import './SiteFooter.css';

const SiteFooter: React.FC = () => {
    const TOS_LINK = 'https://docs.google.com/document/d/1sOPgtPktx4YWc-p5YXWIztGEWusDG4wsP_txWhjpwNw/edit?usp=sharing'
    const PRIVACY_POLICY_LINK = 'https://docs.google.com/document/d/1OhpwpTire_ZKA9fJbf2h6vL2X2GRE1WRnUJdGknfbrU/edit?usp=sharing'

    const openTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <div className='SiteFooter'>
            <div className='SiteFooterMain'>
                <div className='FooterLink' onClick={() => (openTab(PRIVACY_POLICY_LINK))}>Privacy</div>
                <div className='FooterLink' onClick={() => (openTab(TOS_LINK))}>Terms</div>
            </div>
        </div>
    )
}

export default SiteFooter;
