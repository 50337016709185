import React from 'react';
import { useState, useEffect } from 'react';
import './RequestList.css';
import RequestListHeader from './RequestListHeader';
import RequestRow from './RequestRow';
import { INQUIRY_TYPE_TO_REQUEST_TYPE, DASHBOARD_UPDATE_POLLING_FREQUENCY_MS } from '../../constants';
import { useSelector } from 'react-redux';

interface RequestRowInterface {
  inquiry_id: string;
  patient_name: string;
  inquiry_type: string;
  inquiry_status: string;
  inquiry_summary: string;
  inquiry_creation_ts: string;
  claims_date_of_service: string;
}

interface RequestListProps {
  inboxCount: number;
  requestRows: Array<RequestRowInterface>;
  reloadPage: any;
}

function RequestList({ inboxCount, requestRows, reloadPage }: RequestListProps) {
  const [reloadToggle, setReloadToggle] = useState(false);
  const setInbox = useSelector((state: any) => state.setInbox.display);
  const query = useSelector((state: any) => state.setInbox.query);

  useEffect(() => {
    reloadPage(setInbox, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadToggle, setInbox, query]);

  useEffect(() => {
    const interval = setInterval(() => {
      reloadPage(setInbox, query);
    }, DASHBOARD_UPDATE_POLLING_FREQUENCY_MS);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInbox, query]);

  return (
    <>
      <div className='RequestList'>
        <RequestListHeader inboxCount={inboxCount} reloadToggle={reloadToggle} setReloadToggle={setReloadToggle} />
        <div className='RequestListRows'>
          {requestRows.map((row: RequestRowInterface) => (<RequestRow id={row.inquiry_id} Name={row.patient_name} Topic={INQUIRY_TYPE_TO_REQUEST_TYPE[row.inquiry_type]} status={row.inquiry_status} Subject={row.inquiry_summary} Time={row.inquiry_creation_ts} claimsDOS={row.claims_date_of_service} />))}
        </div>
      </div>
    </>
  );
}

export default RequestList;
