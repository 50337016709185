import React from 'react';
import './CustomForm.css';

export const HarborInput = ({ text, label, register, error }) => (
    <div id={label} name={label} className='CustomFormInput-Group'>
        <label for={label} className='CustomFormInput-Label'>{error ? error.message : (text !== undefined ? text : label)}</label>
        <input id={label} {...register(label)} className={error ? "CustomFormInput-Error" : "CustomFormInput-Input"} />
    </div>
);

export const HarborSelect = ({ text, label, register, error, options, expand = true }) => (
    <div id={label} name={label} className={expand ? 'CustomFormDropdown-Group' : 'CustomFormDropdown-Group-Shrink'}>
        <label for={label} className='CustomFormDropdown-Label'>{error ? error.message : (text !== undefined ? text : label)}</label>
        <select id={label} {...register(label)} className={error ? "CustomFormDropdown-Error" : "CustomFormDropdown-Input"}>
            {options.map((index) =>
                (<option key={index.value} value={index.value}>{index.label}</option>)
            )}
        </select>
    </div>
);

export const HarborSelectMultiple = ({ text, label, register, error, options }) => (
    <div id={label} name={label} className='CustomFormDropdown-Group'>
        <label for={label} className='CustomFormDropdown-Label'>{error ? error.message : (text !== undefined ? text : label)}</label>
        <select multiple={true} id={label} {...register(label)} className={error ? "CustomFormDropdown-Error" : "CustomFormDropdown-Input"}>
            {options.map((index) => (<option value={index.value}>{index.label}</option>))}
        </select>
    </div>
);
