import * as yup from "yup";

export const AccountInfoSchema = yup.object().shape({
    specialty: yup.string().required(),
    practice_name: yup.string().required(),
    practice_address: yup.string().required(),
    practice_city: yup.string().required(),
    practice_state: yup.string().required(),
    name: yup.string().required(),
    tax_id: yup
        .string()
        .test('len9', 'Tax id must be 9 digits', val => val === undefined || val === null || val.length === 9)
        .required(),
    practice_zip: yup
        .string()
        .test('len', 'Zip code must be 5 or 9 digits', val => val === undefined || val === null || val.length === 5 || val.length === 9)
        .required(),
    npi: yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .test('len10', 'NPI must be 10 digits', val => val === undefined || val === null || val.length === 10),
    medicaid_provider_number: yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .test('len7', 'Medicaid provider # must be 7 digits', val => val === undefined || val === null || val.length === 7),
    billing_id: yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
});


export const OnboardingInfoSchema = yup.object().shape({
    specialty: yup.string().required("Select a specialty"),
    practices: yup.array().of(
        yup.object().shape({
            providers: yup.array().of(
                yup.object().shape({
                    name: yup.string().required("Include provider's name"),
                    npi: yup
                        .string()
                        .nullable()
                        .transform((v, o) => (o === '' ? null : v))
                        .test('len10', 'NPI must be 10 digits', val => val === undefined || val === null || val.length === 10)
                        .required("Include provider's NPI"),
                    medicaid_provider_number: yup
                        .string()
                        .nullable()
                        .transform((v, o) => (o === '' ? null : v))
                        .test('len7', 'Medicaid provider # must be 7 digits', val => val === undefined || val === null || val.length === 7),
                })
            ),
            name: yup.string().required("Include practice's name"),
            address: yup.string().required("Include practice's address"),
            city: yup.string().required("Include practice's city"),
            state: yup.string().required("Include practice's state"),
            zip: yup
                .string()
                .test('len', 'Zip code must be 5 or 9 digits', val => val === undefined || val === null || val.length === 5 || val.length === 9)
                .required("Include practice's zip code"),
            tax_id: yup
                .string()
                .test('len9', 'Tax id must be 9 digits', val => val === undefined || val === null || val.length === 9)
                .required("Include practice's tax ID"),
            billing_id: yup
                .string()
                .nullable()
                .transform((v, o) => (o === '' ? null : v)),
        })
    ),
});

export const AddTransferPhoneNumberSchema = yup.object().shape({
    transfer_to_rep_name: yup.string().required("Please give this number a name you will recognize"),
    transfer_to_rep_number: yup.string()
        .test('len', 'Phone number must be 10 digits', val => val === undefined || val === null || val.length === 10)
        .matches(/^\d+$/, 'The field should have digits only')
        .required("A phone number is required"),
});

export const ParseTranscriptSchema = yup.object().shape({
    call_end_time: yup.string().optional(),
    representative_name: yup.string().optional(),
    reference_number: yup.string().optional(),
    is_provider_in_network: yup.string().optional(),
    coordination_of_benefits: yup.string().optional(),
    fee_schedule: yup.string().optional(),
    is_calendar_year: yup.string().optional(),
    individual_deductible: yup.string().optional(),
    individual_deductible_used: yup.string().optional(),
    family_deductible: yup.string().optional(),
    family_deductible_used: yup.string().optional(),
    separate_perio_max: yup.string().optional(),
    separate_implant_max: yup.string().optional(),
    maximum: yup.string().optional(),
    accumulation: yup.string().optional(),
    waiting_periods: yup.string().optional(),
    missing_tooth_clause: yup.string().optional(),
    non_covered_procedure_fees: yup.string().optional(),
    sinus_lift_medical_denial: yup.string().optional(),
    implant_limit: yup.string().optional(),
    codes: yup.array().of(
        yup.object().shape({
            is_covered: yup.boolean().optional(),
            coverage_percentage: yup.string().optional(),
            allowed_amount: yup.string().optional(),
            frequency: yup.string().optional(),
            more_info: yup.string().optional()
        })
    ),
});


export const RequestSchema = yup.object().shape({
    type: yup.string().required(),
    claims_date_of_service: yup.string().when("type", {
        is: (val) => String(val) === "CLAIMS",
        then: () => yup.string().required("Date of Service (MM-DD-YYYY)"),
        otherwise: () => yup.string().nullable().notRequired(),
    }),
    eligibility_search_queries: yup.array().when("type", {
        is: (val) => String(val) === "ELIGIBILITY_SEARCH",
        then: () => yup.array().min(1, 'Select a topic').required(),
        otherwise: () => yup.array().nullable().notRequired(),
    }),
    desired_completion_date: yup.string().required("Required Completion Date (MM-DD-YYYY)"),
    patient_name: yup.string().required("Patient Name"),
    dob: yup.string().required("DOB (MM-DD-YYYY)"),
    insurance_name: yup.string().required(),
    insurance_in_network: yup.boolean().when("type", {
        is: "ELIGIBILITY_SEARCH",
        then: (schema) => schema.transform((_, val) => {
            if (val === "null") return null;
            if (val === "") return "";
            if (val === "true") return true;
            if (val === "false") return false;
            return val;
        }).nullable().typeError("Please select coverage or unknown"),
        otherwise: (schema) => schema.optional(),
    }),
    procedure_codes: yup.string().when("type", {
        is: (val) => String(val) === "PRIOR_AUTH_STATUS",
        then: () => yup.string().required().typeError("Please input procedure codes or unknown"),
        otherwise: () => yup.string().optional().nullable(),
    }),
    billed_amount: yup.number().when("type", {
        is: "CLAIMS",
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.nullable().notRequired(),
    }).typeError("Billed amount must be a number"),
    member_id: yup.string().optional().nullable(),
    group_id: yup.string().optional().nullable(),
    patient_address: yup.string().optional().nullable(),
    subject: yup.string().optional().nullable(),
    body: yup.string().optional().nullable(),
    practice_id: yup.string().required(),
    provider_id: yup.string().required(),
    additional_info: yup.string().optional().nullable(),
    diagnosis_codes: yup.string().optional().nullable(),
    place_of_service: yup.string().when("type", {
        is: "ELIGIBILITY_SEARCH",
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
    }),
    is_specialist: yup.boolean().when("type", {
        is: "ELIGIBILITY_SEARCH",
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
    }).typeError("Please select if the provider is a specialist"),
    transfer_to_rep_number: yup.string().nullable(),
});
