import React from 'react';

const ReloadIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>> = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="-0.75" x2="5" y2="-0.75" transform="matrix(0 1 1 0 12 0)" stroke="#454745" stroke-width="1.5" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3885 7C9.93376 9.00399 8.14159 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.14159 1.5 9.93376 2.99601 10.3885 5H11.917C11.441 2.16229 8.973 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C8.973 12 11.441 9.83771 11.917 7H10.3885Z" fill="#454745" />
    </svg>
);

export default ReloadIcon;
