import './RequestModal.css';
import { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { INSURANCES_DEBUG, INSURANCES_PROD_DENTAL, NETWORK, NETWORK_OPTIONAL, INSURANCES_PROD_MEDICAL, ELIGIBILITY_SEARCH_QUERIES_DENTAL, ELIGIBILITY_SEARCH_QUERIES_MEDICAL, TEAM_BASED_ELIGIBILITY_SEARCH_QUERIES, REQUEST_TYPES_DENTAL, REQUEST_TYPES_MEDICAL, ELIGIBILITY_SEARCH_QUERIES_MENTAL_HEALTH, INSURANCES_PROD_MENTAL_HEALTH, Option, ELIGIBILITY_SEARCH_QUERIES_ALL, INSURANCES_PROD_MEDICAL_AND_DENTAL, ELIGIBILITY_SEARCH_QUERIES_MEDICAL_AND_DENTAL, TEAM_BASED_INSURANCES, PLACE_OF_SERVICE_OPTIONS, SPECIALIST_OPTIONS, EMAILS_BULK_CLAIMS_CSV_ENABLED } from '../../constants';
import { HarborInput, HarborSelect, HarborSelectMultiple } from 'HarborForm';
import Cancel from '../Assets/NewRequest/Cancel.svg';
import { useDispatch } from 'react-redux';
import { REQUESTMODAL, NEWONBOARDMODAL, ADD_TRANSFER_PHONE_NUMBER_MODAL, } from 'reducers';
import { setPracticeID } from 'actions';
import { Mixpanel } from 'Analytics';
import baseApi from 'axios-instance';
import React from 'react';
import { RequestSchema } from 'MVP/Shared/ValidationSchema';
import { RequestInfo } from 'MVP/Request/RequestInfo';
import { ProfileInfo } from 'MVP/Request/ProfileInfo';
import { CurrentUserInfo } from 'MVP/CurrentUserInfo';
import { useSelector } from 'react-redux';
import { normalize_place_of_service } from 'utils';
import { Link } from 'react-router-dom';

interface RequestModalProps {
    reloadPage: any;
    debugUser: any;
    prefilledInfo: RequestInfo | undefined;
    profileInfo: ProfileInfo | undefined;
    currentUser: CurrentUserInfo | undefined;
    refreshLastRequest: any;
}

function RequestModal({ reloadPage, debugUser, prefilledInfo, profileInfo, currentUser, refreshLastRequest }: RequestModalProps) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(RequestSchema)
    });

    const dispatch = useDispatch();
    const setInbox = useSelector((state: any) => state.setInbox.display);
    const query = useSelector((state: any) => state.setInbox.query);

    const handleSetPracticeID = (practice_id: string) => {
        dispatch(setPracticeID(practice_id));
    };

    // Watch for changes in the form
    const typeField = watch('type');
    const practiceIdField = watch('practice_id');
    const providerIdField = watch('provider_id');
    const transferPhoneNumberField = watch('transfer_to_rep_number');


    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [practicesOptionsList, setPracticesOptionsList] = useState<Array<Option>>([]);
    const [providersOptionsList, setProvidersOptionsList] = useState<Array<Option>>([]);
    const [transferPhoneNumbersList, setTransferPhoneNumbersList] = useState<Array<Option>>([]);

    // We expect both to be loaded before we can prefill the request form.
    const practicesLoaded = useRef(false);
    const providersLoaded = useRef(false);

    useEffect(() => {
        setValue('claims_date_of_service', undefined);
        setValue('billed_amount', undefined);
        setValue('eligibility_search_queries', undefined);
        setValue('place_of_service', undefined);
        setValue('is_specialist', undefined);
        setValue('insurance_in_network', undefined);
        setValue('diagnosis_codes', undefined);
        setValue('procedure_codes', undefined);
    }, [typeField, setValue, watch]);

    // These useEffects chained together are quite complex.
    // Primary they serve to prefill the request form with information if given.
    // The tricky part is that the dropdowns for practices and providers are populated asynchronously.
    // So we need to wait for them to be populated before we can prefill the form.
    // The tax id is also needed to populate the providers dropdown, so it needs to be provided before we can populate the providers dropdown.
    // The two Ref variables above are used to guarantee an order of execution because useEffects are not guaranteed to execute in order.
    useEffect(() => {
        // Fills the request with information if given to make it easier for the user.
        const prefillRequest = (prefilledInfo: RequestInfo | undefined) => {
            if (prefilledInfo == null) return;
            if (prefilledInfo.practice_id == null) return;
            setValue('type', prefilledInfo['type']);
            setValue('insurance_name', prefilledInfo['insurance_name']);
            //TODO(nathan): Fix the yup resolver to always take the same type
            // @ts-ignore
            setValue('insurance_in_network', prefilledInfo['insurance_in_network']);
            setValue('practice_id', prefilledInfo['practice_id'])
            setValue('provider_id', prefilledInfo['provider_id']);
            if (prefilledInfo.eligibility_search_queries != null) {
                setValue('eligibility_search_queries', prefilledInfo.eligibility_search_queries);
            }
            if (prefilledInfo.place_of_service != null) {
                setValue('place_of_service', normalize_place_of_service(prefilledInfo.place_of_service));
            }
            if (prefilledInfo.is_specialist != null) {
                setValue('is_specialist', prefilledInfo.is_specialist);
            }
        };

        if (practicesLoaded && practicesLoaded.current === true && providersLoaded && providersLoaded.current === true) { return; }
        // All lists are expected to contain at least the default (Select) option and Other along with the actual real practices and providers. This means they must contain at least three elements.
        if (practicesOptionsList.length > 2 && practicesLoaded.current === false) {
            if (prefilledInfo == null) return;
            if (prefilledInfo.practice_id == null) return;
            // The tax id is needed to prefill the provider dropdown.
            setValue('practice_id', prefilledInfo['practice_id']);
            practicesLoaded.current = true;
        }
        if (providersOptionsList.length > 2 && providersLoaded.current === false) {
            providersLoaded.current = true;
        }
        if (practicesLoaded.current === true && providersLoaded.current === true) {
            prefillRequest(prefilledInfo);
        }
    }, [practicesOptionsList, providersOptionsList, prefilledInfo, setValue]);


    useEffect(() => {
        // Loads the practice dropdown options.
        const loadPracticeOptions = (profileInfo: ProfileInfo | undefined) => {
            if (profileInfo == null) return;
            let practicesToLoad = profileInfo.practices;
            if (practicesToLoad == null) return;
            let practiceOptions = [{ label: "Select Practice", value: '' }];
            if (practicesToLoad.length === 0) return practiceOptions;
            practicesToLoad.forEach((val) => {
                practiceOptions.push({ label: val.name, value: val.id });
            })
            practiceOptions.push({ label: "Other", value: "OTHER" });
            setPracticesOptionsList(practiceOptions);
        };

        loadPracticeOptions(profileInfo);
    }, [practicesOptionsList.length, profileInfo]);

    useEffect(() => {
        const loadProviderOptions = (profileInfo: ProfileInfo | undefined) => {
            if (profileInfo == null) return;
            let practices = profileInfo.practices;
            if (practices == null) return;
            let providerOptions = [{ label: "Select Provider", value: '' }];
            if (practices.length === 0) return providerOptions;
            practices.forEach((val) => {
                if (val.id === practiceIdField) {
                    val.providers.forEach((sub) => {
                        providerOptions.push({ label: sub.name, value: sub.id });
                    });
                }
            });
            providerOptions.push({ label: "Other", value: "OTHER" });
            setProvidersOptionsList(providerOptions);
        };
        loadProviderOptions(profileInfo);
    }, [practiceIdField, profileInfo, providersOptionsList.length]);

    useEffect(() => {
        const loadTransferPhoneNumbers = (profileInfo: ProfileInfo | undefined) => {
            let transferPhoneNumberOptions: Array<Option> = [{ label: 'Health Harbor AI', value: '' }];
            setTransferPhoneNumbersList(transferPhoneNumberOptions);
            if (profileInfo == null) return;
            let transferPhoneNumbers = profileInfo.phoneNumbers;
            if (transferPhoneNumbers == null) return;
            transferPhoneNumbers.forEach((val) => {
                transferPhoneNumberOptions.push({ label: val.name, value: val.phoneNumber });
            });
            transferPhoneNumberOptions.push({ label: "Add your staff member", value: "OTHER" });
            setTransferPhoneNumbersList(transferPhoneNumberOptions);
        };
        loadTransferPhoneNumbers(profileInfo);
    }, [profileInfo]);

    // Trigger New Onboard of a practice or provider
    useEffect(() => {
        if (practiceIdField === "OTHER" || providerIdField === "OTHER") {
            // This clears the form so that the user can fill it out with new information.
            handleSetPracticeID(practiceIdField);
            dispatch({ type: NEWONBOARDMODAL.display });
            dispatch({ type: REQUESTMODAL.hide });
        }
        // nolint justification: This only needs to run when practiceIdField or providerIdField changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceIdField, providerIdField]);

    useEffect(() => {
        if (transferPhoneNumberField === "OTHER") {
            dispatch({ type: ADD_TRANSFER_PHONE_NUMBER_MODAL.display });
            dispatch({ type: REQUESTMODAL.hide });
        }
    }, [transferPhoneNumberField, dispatch]);


    // Only set Eligibility search if the type is eligibility search.
    useEffect(() => {
        if (typeField !== "ELIGIBILITY_SEARCH") {
            setValue('eligibility_search_queries', undefined);
        }
    }, [typeField, setValue]);

    const onSubmit = async (data: any) => {
        setIsSubmitDisabled(true);
        // If you set an dropdown field to null in the schema, it will automatically set to the text of the option.
        if (data.insurance_in_network === "Unknown") {
            data.insurance_in_network = null;
        }

        await baseApi.post('/dashboard/new_inquiry', data, { withCredentials: true }).then(response => {
            Mixpanel.track('New request submitted', { $request_type: data.type })
            dispatch({ type: REQUESTMODAL.hide });
            reloadPage(setInbox, query);
            refreshLastRequest();
        }).catch(error => {
            Mixpanel.track('Error: New Request', { error: error, data: data });
            const message = error.response?.data?.detail || error.response?.data;
            console.warn(message);
            alert(message);
        });
        setIsSubmitDisabled(false);
    };

    var eligibilitySearchOptions: Array<Option> = []
    var requestTypes: Array<Option> = [];
    var insuranceOptions: Array<Option> = [];
    switch (profileInfo?.specialty) {
        case "MEDICAL":
            eligibilitySearchOptions = ELIGIBILITY_SEARCH_QUERIES_MEDICAL;
            requestTypes = REQUEST_TYPES_MEDICAL;
            insuranceOptions = INSURANCES_PROD_MEDICAL;
            break;
        case "DENTAL":
            eligibilitySearchOptions = ELIGIBILITY_SEARCH_QUERIES_DENTAL;
            requestTypes = REQUEST_TYPES_DENTAL;
            insuranceOptions = INSURANCES_PROD_DENTAL;
            break;
        case "MEDICAL_AND_DENTAL":
            eligibilitySearchOptions = ELIGIBILITY_SEARCH_QUERIES_MEDICAL_AND_DENTAL;
            requestTypes = REQUEST_TYPES_DENTAL;
            insuranceOptions = INSURANCES_PROD_MEDICAL_AND_DENTAL;
            break;
        case "MENTAL_HEALTH":
            eligibilitySearchOptions = ELIGIBILITY_SEARCH_QUERIES_MENTAL_HEALTH;
            insuranceOptions = INSURANCES_PROD_MENTAL_HEALTH;
            // Medical and mental health have similar enough request types that we can use the same ones.
            requestTypes = REQUEST_TYPES_MEDICAL;
            break;
        default:
            eligibilitySearchOptions = [];
    }

    if (currentUser != null && currentUser.team_id in TEAM_BASED_ELIGIBILITY_SEARCH_QUERIES) {
        eligibilitySearchOptions =
            TEAM_BASED_ELIGIBILITY_SEARCH_QUERIES[currentUser.team_id as keyof typeof TEAM_BASED_ELIGIBILITY_SEARCH_QUERIES];
    }

    if (currentUser != null && currentUser.team_id in TEAM_BASED_INSURANCES) {
        insuranceOptions = TEAM_BASED_INSURANCES[currentUser.team_id as keyof typeof TEAM_BASED_INSURANCES];
    }

    if (currentUser != null && debugUser) {
        eligibilitySearchOptions = ELIGIBILITY_SEARCH_QUERIES_ALL;
        insuranceOptions = INSURANCES_DEBUG;
    }

    var isBulkClaimsEnabled = false
    if (currentUser != null && (debugUser || EMAILS_BULK_CLAIMS_CSV_ENABLED.includes(currentUser.email))) {
        isBulkClaimsEnabled = true
    }

    return (
        <div className='RequestModal'>
            <div className='RequestModal-Header'>
                <div className='RequestModal-HeaderTitle'>New Request</div>
                <button className='nakedButton' onClick={() => dispatch({ type: REQUESTMODAL.hide })}><img src={Cancel.toString()} className='CancelIcon' alt="Cancel Icon" /></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='RequestModal-form'>
                <div className='RequestModal-row'>
                    <HarborSelect label={"practice_id"} text={"practice"} register={register} error={errors.practice_id} options={practicesOptionsList} />
                    <HarborSelect label={"provider_id"} text={"provider"} register={register} error={errors.provider_id} options={providersOptionsList} />
                </div>
                {typeField === "ELIGIBILITY_SEARCH" &&
                    <div className='RequestModal-row'>
                        <HarborSelect label={"place_of_service"} text={"place of service"} register={register} error={errors.place_of_service} options={PLACE_OF_SERVICE_OPTIONS} />
                        <HarborSelect label={"is_specialist"} text={"specialist"} register={register} error={errors.is_specialist} options={SPECIALIST_OPTIONS} />
                    </div>
                }
                <div className='RequestModal-row'>
                    <HarborSelect label={"type"} register={register} error={errors.type} options={requestTypes} expand={false} text={undefined} />
                    {typeField === "CLAIMS" &&
                        <>
                            <HarborInput label={"claims_date_of_service"} text={"claims date of service (MM-DD-YYYY)"} register={register} error={errors.claims_date_of_service} />
                            <HarborInput label={"billed_amount"} text={"billed amount"} register={register} error={errors.billed_amount} />
                        </>
                    }
                    {typeField === "ELIGIBILITY_SEARCH" &&
                        <HarborSelectMultiple label={"eligibility_search_queries"} text={"eligibility search queries"} register={register} error={errors.eligibility_search_queries} options={eligibilitySearchOptions} />
                    }
                    {typeField === "PRIOR_AUTH_STATUS" &&
                        <HarborInput label={"procedure_codes"} text={"procedure codes"} register={register} error={errors.procedure_codes} />
                    }
                </div>
                {typeField === "ELIGIBILITY_SEARCH" && (profileInfo?.specialty === "MEDICAL" || profileInfo?.specialty === "MENTAL_HEALTH") &&
                    <HarborInput label={"diagnosis_codes"} text={"diagnosis codes (optional)"} register={register} error={errors.diagnosis_codes} />
                }
                <div className='RequestModal-row'>
                    <HarborSelect label={"insurance_name"} text={"insurance name"} register={register} error={errors.insurance_name} options={insuranceOptions} />
                    {typeField === "ELIGIBILITY_SEARCH" && <HarborSelect label={"insurance_in_network"} text={"insurance coverage"} register={register} error={errors.insurance_in_network} options={typeField === "ELIGIBILITY_SEARCH" ? NETWORK_OPTIONAL : NETWORK} />}
                </div >
                <div className='RequestModal-row'>
                    <HarborInput label={"desired_completion_date"} text={"results needed by (MM-DD-YYYY)"} register={register} error={errors.desired_completion_date} />
                </div>
                <div className='RequestModal-row'>
                    <HarborInput label={"patient_name"} text={"patient name"} register={register} error={errors.patient_name} />
                    <HarborInput label={"dob"} text={"dob (MM-DD-YYYY)"} register={register} error={errors.dob} />
                </div>
                <div className='RequestModal-row'>
                    <HarborInput label={"member_id"} text={"member id/ssn"} register={register} error={errors.member_id} />
                    <HarborInput label={"group_id"} text={"group id"} register={register} error={errors.group_id} />
                    <HarborInput label={"patient_address"} text={"patient address (optional)"} register={register} error={errors.patient_address} />
                </div>
                {debugUser ?
                    <div className='RequestModal-row'>
                        <HarborSelect label={"transfer_to_rep_number"} text={"call handled by"} register={register} error={errors.transfer_to_rep_number} options={transferPhoneNumbersList} />
                    </div>
                    : <div></div>
                }
                <div className='RequestModal-row'>
                    <HarborInput label={"additional_info"} text={"additional info (optional)"} register={register} error={errors.additional_info} />
                </div>
                <div className='Footer'>
                    <input type="submit" className='RequestSubmitButton' disabled={isSubmitDisabled} />
                    {isBulkClaimsEnabled && <Link to="/dashboard/csv" target="_blank" rel="noopener noreferrer"> Submit Bulk Requests</Link>}
                </div>
            </form >
        </div >
    );
}

export default RequestModal;
