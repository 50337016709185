import React, { useState } from 'react';
import './Searchbar.css';
import SearchIcon from 'MVP/Assets/SearchIcon';
import ClearIcon from 'MVP/Assets/ClearIcon';
import { useDispatch } from 'react-redux';
import { ACCOUNT, INBOX, REQUEST } from 'reducers';
import { setSearchQuery } from 'actions';

function Searchbar() {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [searchActive, setSearchActive] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleSearch = () => {
        if (searchValue === '') return;
        dispatch({ type: INBOX.search_inbox });
        dispatch(setSearchQuery(searchValue));
        dispatch({ type: REQUEST.hide });
        dispatch({ type: ACCOUNT.hide })
        setSearchActive(true);
    };

    const handleClear = () => {
        dispatch({ type: INBOX.current_user_inbox });
        setSearchActive(false);
        setSearchValue('');
    }

    return (
        <div className="search-container">
            <input
                type="text"
                className="search-input"
                placeholder="Patient Name"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={(e) => { if (e.key === "Enter") handleSearch() }}
            />
            {
                searchActive ?
                    <i className="material-icons search-icon" onClick={handleClear}>
                        <ClearIcon />
                    </i>
                    :
                    <i className="material-icons search-icon" onClick={handleSearch}>
                        <SearchIcon />
                    </i>
            }
        </div>
    );
}

export default Searchbar;
