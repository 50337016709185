import axios from 'axios';

const LOCAL_HOSTNAMES = ['localhost', '127.0.0.1'];

const isLocalhostServer = () => {
    for (const hostname of LOCAL_HOSTNAMES) {
        if (window.location.href.includes(hostname)) {
            console.info('isLocalhostServer: Found localhost server');
            return true;
        }
    }
    return false;
};

const isStagingServer = () => {
    if (window.location.href.includes('staging')) {
        return true;
    } else {
        return false;
    }
};

const isDemoServer = () => {
    if (window.location.href.includes('demo')) {
        return true;
    } else {
        return false;
    }
}

const determineUrl = () => {
    return window.location.protocol + '//' + window.location.host;
};

const baseApi = axios.create({
    baseURL: determineUrl(),
});

// Request interceptor
baseApi.interceptors.request.use(
    (config) => {
        // Add any request headers or other configurations you need to set for each request
        // e.g., setting an authorization header with a token if the user is logged in
        // const token = 'YourAuthToken';
        // config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        // Do something with the request error (e.g., log it)
        return Promise.reject(error);
    }
);

// Response interceptor
baseApi.interceptors.response.use(
    (response) => {
        // Handle successful responses (status code 2xx)
        return response;
    },
    (error) => {
        // Handle error responses (status codes other than 2xx)
        // If the response status is 401 (Unauthorized), you can log the user out here
        if (error.response && error.response.status === 401) {
            // Perform the logout action (e.g., clearing user data from local storage)
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
);

export const baseURL = determineUrl();
export const isLocalhost = isLocalhostServer();
export const isStaging = isStagingServer();
export const isDemo = isDemoServer();

export default baseApi;
