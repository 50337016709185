import React from 'react';
import './AccountInfo.css';
import { useEffect } from 'react';
import { HarborInput, HarborSelect } from 'HarborForm';
import { SPECIALTY_OPTIONS } from '../../constants';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Mixpanel } from 'Analytics';
import baseApi from 'axios-instance';
import { OnboardingInfoSchema } from 'MVP/Shared/ValidationSchema';
import { useSelector, useDispatch } from 'react-redux';
import { ACCOUNTSAVED } from 'reducers';

function AccountInfo({ refreshProfileInfo }) {
    const showAccountSaved = useSelector(state => state.showAccountSaved.show);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(OnboardingInfoSchema)
    });

    const onSubmit = async (data) => {

        await baseApi.post('/onboarding', data, { withCredentials: true }).then(response => {
            Mixpanel.track('Onboarding Updated');
            refreshProfileInfo();
            dispatch({ type: ACCOUNTSAVED.display });
            setTimeout(() => {
                dispatch({ type: ACCOUNTSAVED.hide });
            }, 1500);
        }).catch(error => {
            Mixpanel.track('Error: Onboarding', { error: error });
            const message = error.response?.data?.detail || error.response?.data;
            alert(message);
        });
    };


    useEffect(() => {
        baseApi.get('/profile').then(response => {
            setValue('specialty', response.data.specialty);
            setValue('practices', response.data.practices);
        }).catch(error => {
            console.log(error);
            alert(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const practices = watch('practices');

    return (
        <div className='AccountInfo'>
            {showAccountSaved ? <div className='AccountInfo-Saved'>Saved</div> : <></>}
            <div className='AccountInfo-Title'>Practice Info</div>
            <form onSubmit={handleSubmit(onSubmit)} className='AccountFormWrapper'>
                {practices === undefined && <div className='AccountFormSubtitle'>Loading data...</div>}
                <div className='AccountFormContent'>
                    <div className='AccountFormContent-Header'>
                        <div className='AccountFormRow'>
                            {practices && <HarborSelect label={"specialty"} text={"Specialty"} register={register} error={errors.specialty} options={SPECIALTY_OPTIONS} />}
                        </div>
                    </div>
                    {practices && practices.map((val, index) => (
                        <div className='AccountFormCard'>
                            <div className='AccountFormSubtitle'>Office Info</div>
                            <div className='AccountFormRow'>
                                <HarborInput label={`practices[${index}].name`} text={"Practice Name"} register={register} error={errors.practices?.[index]?.name} />
                                <HarborInput label={`practices[${index}].tax_id`} text={"Tax ID"} register={register} error={errors.practices?.[index]?.tax_id} />
                                <HarborInput label={`practices[${index}].billing_id`} text={"Billing ID"} register={register} error={errors.practices?.[index]?.billing_id} />
                            </div>
                            <div className='AccountFormSubtitle' />
                            <div className='AccountFormRow'>
                                <HarborInput label={`practices[${index}].address`} text={"Address"} register={register} error={errors.practices?.[index]?.address} />
                                <HarborInput label={`practices[${index}].city`} text={"City"} register={register} error={errors.practices?.[index]?.city} />
                                <HarborInput label={`practices[${index}].state`} text={"State"} register={register} error={errors.practices?.[index]?.state} />
                                <HarborInput label={`practices[${index}].zip`} text={"Zip Code"} register={register} error={errors.practices?.[index]?.zip} />
                            </div>
                            <div className='AccountFormSubtitle' />
                            <div className='AccountFormSubtitle'>Provider Info</div>
                            {val.providers && val.providers.map((v, i) => (
                                <>
                                    <div className='AccountFormRow'>
                                        <HarborInput label={`practices[${index}].providers[${i}].name`} text={"Provider's Name"} register={register} error={errors.practices?.[index]?.providers?.[i]?.name} />
                                        <HarborInput label={`practices[${index}].providers[${i}].npi`} text={"NPI"} register={register} error={errors.practices?.[index]?.providers?.[i]?.npi} />
                                        <HarborInput label={`practices[${index}].providers[${i}].medicaid_provider_number`} text={"medicaid provider #"} register={register} error={errors.practices?.[index]?.providers?.[i]?.medicaid_provider_number} />
                                    </div>
                                    <div className='AccountFormSubtitle' />
                                </>
                            ))
                            }
                        </div>
                    ))
                    }

                </div>
                {practices &&
                    <div className='AccountFormNav'>
                        <input type="submit" className='AccountNextButton' />
                    </div>}
            </form>
        </div>
    );
}

export default AccountInfo;
