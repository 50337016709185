import React from 'react';
import './RequestListHeader.css';
import ReloadIcon from 'MVP/Assets/ReloadIcon';

interface RequestListHeaderProps {
    inboxCount: number;
    reloadToggle: boolean;
    setReloadToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

function RequestListHeader({ inboxCount, reloadToggle, setReloadToggle }: RequestListHeaderProps) {

    return (
        <div className='RequestListHeader'>
            <div className="Reload-wrapper">
                <div className="Reload" onClick={() => setReloadToggle(!reloadToggle)}>
                    <ReloadIcon />
                </div>
            </div>
            <div className='Count'>{inboxCount}</div>
        </div>

    );
}

export default RequestListHeader;
