import React from 'react';
import './MVPHeader.css';
import Logo from './Assets/Logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { PROFILEMODAL, ACCOUNT, REQUESTMODAL } from 'reducers';
import Searchbar from 'MVP/Searchbar/Searchbar';

function Header({ currentUser }) {
    const showAccount = useSelector(state => state.showAccount.show);
    const dispatch = useDispatch();

    const toDashboard = () => {
        dispatch({ type: PROFILEMODAL.hide });
        dispatch({ type: ACCOUNT.hide });
    }

    return (
        <div className='MVPHeader'>
            <div className='MVPHeaderLeft'>
                <img src={Logo} className="MVPHeader-Logo" alt="MVPHeader-Logo" onClick={toDashboard} />
                <div className="MVPHeader-Logo-Text">{showAccount ? "Account" : "Requests"}</div>
            </div>
            <Searchbar />
            <div className='HeaderSpacer' />
            <div className='RequestsNav' onClick={() => {
                dispatch({ type: PROFILEMODAL.hide });
                dispatch({ type: REQUESTMODAL.hide });
                dispatch({ type: ACCOUNT.display });
            }
            }>
                Account
            </div>
            <div className='MVPHeaderRight'>
                <button className='Avatar' onClick={() => dispatch({ type: PROFILEMODAL.toggle })}>
                    <div className='Avatar-Text'>Health Harbor</div>
                    <img src={currentUser.picture_url} className="Avatar-Icon" alt="Avatar Icon" />
                </button>
            </div>
        </div>

    );
}

export default Header;
