import { FETCH_REQUEST } from 'reducers';

const initialState = {
    loading: false,
    requestDetails: null,
    requestError: null,
    requestID: null,
};

const fetchRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REQUEST.sent:
            return { ...state, requestID: action.payload, loading: true, error: null };
        case FETCH_REQUEST.success:
            return { ...state, loading: false, requestDetails: action.payload, requestError: null };
        case FETCH_REQUEST.failure:
            return { ...state, loading: false, requestError: action.payload };
        default:
            return state;
    }
};

export default fetchRequestReducer;
