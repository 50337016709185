// Desc: Thunk to fetch request details from the API
import { requestSent, requestSuccess, requestFailure } from 'actions';
import baseApi from 'axios-instance';

const fetchRequest = (requestId) => async (dispatch) => {
    dispatch(requestSent(requestId));

    try {
        // Fetch request details from the backend database
        const response = await baseApi.get("/dashboard/data/" + requestId);
        const requestDetails = await response.data;

        dispatch(requestSuccess(requestDetails));
    } catch (error) {
        dispatch(requestFailure(error.message));
    }
};

export default fetchRequest;
