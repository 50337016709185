import { PLACE_OF_SERVICE_AMBULATORY_SURGICAL_CENTER, PLACE_OF_SERVICE_DOCTOR_OFFICE, PLACE_OF_SERVICE_INPATIENT_HOSPITAL, PLACE_OF_SERVICE_OUTPATIENT_HOSPITAL, PLACE_OF_SERVICE_PHARMACY, PLACE_OF_SERVICE_TELEHEALTH } from "./constants";
import React from "react";

export const varNameToTitle = function (var_name: string): string {
    return var_name.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const constantNameCaseToTitle = function (constant_name: string): string {
    return constant_name.toLowerCase().split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const formatDateTime = (utcDateString: string | null): string => {
    if (utcDateString === null) return "N/A";
    const utcDate = new Date(utcDateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };
    const localDateString = utcDate.toLocaleString('en-US', options);
    return localDateString;
};

export const formatDate = (dateString: string | null): string | null => {
    if (dateString === null) return null;
    // Manually string parse date in the format MM-DD-YYYY
    const month = dateString.substring(0, 2);
    const day = dateString.substring(3, 5);
    const year = dateString.substring(6, 10);
    dateString = month + "/" + day + "/" + year;
    return dateString;
};

export function formatCell(key: string, value: string | number | boolean | null): React.ReactNode {
    if (value == null) {
        return "";
    }
    if (typeof value === "boolean") {
        return insertCheckbox(value);
    } else if (typeof value === "number") {
        if (key.includes("percentage")) {
            return value + "%";
        }
        return "$" + value;
    }
    return value;
}

export const insertCheckbox = (value: boolean | null) => {
    if (value == null) {
        return <div />;
    }
    else return <div className={value ? "true-cell" : "false-cell"} />;
};
export function addDollarSign(value: number | null): React.ReactNode {
    if (value == null) {
        return "";
    }
    return "$" + value;
}
export function addPercentSign(value: number | null): React.ReactNode {
    if (value == null) {
        return "";
    }
    return value + "%";
}


export function normalize_place_of_service(place_of_service_str: string): string {
    const place_of_service = place_of_service_str.toLowerCase();
    if (place_of_service.includes('telehealth') || place_of_service.includes('virtual')) { return PLACE_OF_SERVICE_TELEHEALTH };
    if (place_of_service.includes('office')) { return PLACE_OF_SERVICE_DOCTOR_OFFICE };
    if (place_of_service.includes('pharmacy')) { return PLACE_OF_SERVICE_PHARMACY };
    if (place_of_service.includes('outpatient')) { return PLACE_OF_SERVICE_OUTPATIENT_HOSPITAL };
    if (place_of_service.includes('inpatient')) { return PLACE_OF_SERVICE_INPATIENT_HOSPITAL };
    if (place_of_service.includes('ambulatory')) { return PLACE_OF_SERVICE_AMBULATORY_SURGICAL_CENTER };
    return '';
}
