// Given a valid eligibility search results object, transforms it into a format for display on the frontend.

import { Column } from "react-table";
import EligibilityQueryResults, { BenefitsDetails, CallDetails, Maximums, PlanInformation, ProcedureCategory, ProcedureCodeInfo, TreatmentHistory } from "./EligibilityQueryResults";
import { Section, TableResults } from "./TableResults";
import { FULL_BENEFIT_DETAILS, FULL_CATEGORY_DETAILS, PLACEHOLDER_COLUMN } from "./EligibilityQueryResultsColumns";
import { formatDateTime, formatDate, varNameToTitle, formatCell } from "utils";

const DATE_TIME_FIELDS = new Set<string>(['call_end_time']);
const DATE_FIELDS = new Set<string>(['effective_date', 'termination_date']);
export const customDatesJsonStringParser = function (key: string, value: string) {
    if (DATE_TIME_FIELDS.has(key)) {
        return formatDateTime(value);
    }
    if (DATE_FIELDS.has(key)) {
        return formatDate(value);
    }
    return value;
}


const GENERIC_SECTIONS = ['call_details', 'maximums', 'plan_information', 'claims_information', 'extra_dental_info', 'extra_medical_info'];
// Transforms results from a eligibility query into a format for display on the frontend.
export const transformEligibilityQueryResults = function (eligibility_query_results: EligibilityQueryResults): TableResults {
    // Keeping track of specialties like this isn't great, we should ideally be able to determine this elsewhere or pass it from the backend.
    const is_dental_inquiry = eligibility_query_results.extra_dental_info != null;
    var sections = [];
    // Generic sections are hard coded as the sections from the eligibility query results.
    for (const section_name of GENERIC_SECTIONS) {
        const partial_results_section = eligibility_query_results[section_name as keyof EligibilityQueryResults];
        if (partial_results_section == null) {
            continue;
        }
        if (section_name === 'call_details') {
            const call_details_array = partial_results_section as CallDetails[];
            for (const call_detail of call_details_array) {
                const section = createGenericSection(call_detail, varNameToTitle(section_name), is_dental_inquiry);
                sections.push(section);
            }
        } else {
            const section = createGenericSection(partial_results_section, varNameToTitle(section_name), is_dental_inquiry);
            sections.push(section);
        }
    }
    // Special case for procedure codes, categories and treatment history.
    if (eligibility_query_results.categories) {
        const categories_section = createCategoriesSection(eligibility_query_results.categories, is_dental_inquiry);
        sections.push(categories_section);
    }
    if (eligibility_query_results.procedure_codes) {
        const procedure_codes_sections = createProcedureCodesSections(eligibility_query_results.procedure_codes, is_dental_inquiry);
        sections.push(...procedure_codes_sections);
    }
    if (eligibility_query_results.treatment_history) {
        const treatment_history_section = createTreatmentHistorySection(eligibility_query_results.treatment_history);
        sections.push(treatment_history_section);
    }
    const transformedResults: TableResults = {
        sections: sections,
    };
    return transformedResults;
}


const createGenericSection = function (object: Object, section_name: string, is_dental_inquiry: boolean = false): Section<Object> {
    const columns: Array<Column<Object>> = Object.keys(object).filter((key) => object[key as keyof Object] != null).map((key) => {
        // States that the accessor is explicitly a part of the object which is always true since we're using object.keys.
        return { Header: varNameToTitle(key), accessor: key as "propertyIsEnumerable", Cell: ({ value }: { value: any }) => formatCell(key, value) };
    });
    // TODO(alan): remove this special casing for cleaner code.
    // Special case termination date because "null" termination date should still be recorded.
    if (section_name === "Plan Information") {
        var planInformation: PlanInformation = object as PlanInformation;
        if (planInformation['termination_date'] == null) {
            columns.push({ Header: "Termination Date", accessor: "termination_date" as "propertyIsEnumerable" });
            planInformation.termination_date = "None";
        }
    } else if (section_name === "Maximums") {
        const possible_null_maximum_fields = ['coverage_limit', 'individual_deductible', 'family_deductible']
        if (!is_dental_inquiry){
            possible_null_maximum_fields.push('individual_out_of_pocket_maximum')
            possible_null_maximum_fields.push('family_out_of_pocket_maximum')
        }
        var maximums: Maximums = object as Maximums;
        for (const field of possible_null_maximum_fields) {
            if ((field in maximums === false) || (maximums[field as keyof Maximums] == null)) {
                columns.push({ Header: varNameToTitle(field), accessor: field as "propertyIsEnumerable" });
                // Ignore type error because we know the field is in possible_null_maximum_fields and can be a string.
                // @ts-ignore
                maximums[field as keyof Maximums] = "None";
            }
        }
    }
    const rows = [object];
    const section: Section<Object> = {
        title: section_name,
        columns: columns,
        rows: rows,
    };
    return section;
}

const createProcedureCodesSections = function (procedure_codes: Array<[ProcedureCodeInfo, BenefitsDetails]>, is_dental_inquiry: boolean = false): Array<Section<BenefitsDetails & ProcedureCodeInfo>> {
    // If BenefitsDetails.is_covered is false, set all other attributes of the BenefitsDetails to null.
    for (const [, benefits_details] of procedure_codes) {
        if (!benefits_details.is_covered) {
            benefits_details.copay_amount = null;
            benefits_details.coverage_percentage = null;
            benefits_details.coinsurance_percentage = null;
            benefits_details.allowed_fee = null;
            benefits_details.frequency_limitations = null;
            benefits_details.is_prior_auth_required = null;
            benefits_details.is_deductible_waived = null;
            benefits_details.prior_auth_info = null;
            benefits_details.category = null;
            benefits_details.age_limit = null;
            benefits_details.is_downgraded = null;
            benefits_details.downgraded_info = null;

            // More info is intentionally not set as null so we can display any details about why the procedure is not covered.
        }
        if (benefits_details.coinsurance_percentage != null && is_dental_inquiry) {
            benefits_details.coverage_percentage = 100 - benefits_details.coinsurance_percentage;
            benefits_details.coinsurance_percentage = null;
        }
    }

    // Group by procedure name, also collect names of all unique properties.
    const procedure_name_to_procedure_info_and_benefit_details = new Map<string, Array<[ProcedureCodeInfo, BenefitsDetails]>>();
    const all_properties = new Set<string>();
    all_properties.add('procedure_code');
    for (const [procedure_code_info, benefits_details] of procedure_codes) {
        var procedure_name = procedure_code_info.procedure_name || "Other Procedures";
        var maybe_array_of_procedure_info_and_benefit_details = procedure_name_to_procedure_info_and_benefit_details.get(procedure_name);
        if (maybe_array_of_procedure_info_and_benefit_details != null) {
            maybe_array_of_procedure_info_and_benefit_details.push([procedure_code_info, benefits_details]);
        } else {
            procedure_name_to_procedure_info_and_benefit_details.set(procedure_name, [[procedure_code_info, benefits_details]]);
        }
        for (const property in benefits_details) {
            if (benefits_details[property as keyof BenefitsDetails] != null && benefits_details[property as keyof BenefitsDetails] !== "") {
                all_properties.add(property);
            }
        }
    }

    var sections = [];
    for (const [procedure_name, procedure_info_and_benefit_details] of procedure_name_to_procedure_info_and_benefit_details) {
        var columns = FULL_BENEFIT_DETAILS.filter((column) => {
            if (column.accessor == null) { return false; }
            return all_properties.has(column.accessor.toString());
        });

        // This occurs when all the properties are null (nothing is covered).
        if (columns.length === 1) {
            columns = columns.concat(PLACEHOLDER_COLUMN as Column<BenefitsDetails & ProcedureCodeInfo>);
        }

        const rows = []
        for (const [procedure_code_info, benefits_details] of procedure_info_and_benefit_details) {
            const row = { ...procedure_code_info, ...benefits_details, row_override: benefits_details.is_covered ? null : "No Coverage" + (benefits_details.more_info ? `: ${benefits_details.more_info}` : "") };
            rows.push(row);
        }

        const section: Section<BenefitsDetails & ProcedureCodeInfo> = {
            title: varNameToTitle(procedure_name),
            columns: columns,
            rows: rows,
        };
        sections.push(section);
    }
    return sections;
}

const createCategoriesSection = function (categories: Record<ProcedureCategory, BenefitsDetails>, is_dental_inquiry: boolean = false): Section<BenefitsDetails> {
    const all_properties = new Set<string>();
    all_properties.add('category');
    for (const [procedure_category, benefits_details] of Object.entries(categories)) {
        if (!benefits_details.is_covered) {
            benefits_details.copay_amount = null;
            benefits_details.coinsurance_percentage = null;
            benefits_details.frequency_limitations = null;
            benefits_details.is_prior_auth_required = null;
            benefits_details.is_deductible_waived = null;
            benefits_details.prior_auth_info = null;
            benefits_details.category = null;
            benefits_details.age_limit = null;
            benefits_details.is_downgraded = null;
            benefits_details.downgraded_info = null;

            // More info is intentionally not set as null so we can display any details about why the procedure is not covered.
            continue;
        }
        if (benefits_details.coinsurance_percentage != null && is_dental_inquiry) {
            benefits_details.coverage_percentage = 100 - benefits_details.coinsurance_percentage;
            benefits_details.coinsurance_percentage = null;
        }
        for (const property in benefits_details) {
            if (benefits_details[property as keyof BenefitsDetails] != null && benefits_details[property as keyof BenefitsDetails] !== "") {
                all_properties.add(property);
            }
        }
        benefits_details.category = procedure_category as ProcedureCategory;
    }
    var columns = FULL_CATEGORY_DETAILS.filter((column) => {
        if (column.accessor == null) { return false; }
        return all_properties.has(column.accessor.toString());
    });
    // This occurs when all the properties are null (nothing is covered).
    if (columns.length === 1) {
        columns = columns.concat(PLACEHOLDER_COLUMN);
    }
    const rows = []
    for (const [category, benefits_details] of Object.entries(categories)) {
        const row = { ...benefits_details, category: category as ProcedureCategory, row_override: benefits_details.is_covered ? null : "No Coverage" + (benefits_details.more_info ? `: ${benefits_details.more_info}` : "") };
        rows.push(row);
    }

    const section: Section<BenefitsDetails> = {
        title: 'Categories',
        columns: columns,
        rows: rows,
    };
    return section;

}

const createTreatmentHistorySection = function (treatment_history: Record<string, Array<string>>): Section<TreatmentHistory> {
    const historyObjects: TreatmentHistory[] = [];
    for (const [date, procedure_codes] of Object.entries(treatment_history)) {
        const new_object: TreatmentHistory = {
            date: formatDate(date), procedure_codes: procedure_codes
        }
        historyObjects.push(new_object);
    }
    const columns: Column<TreatmentHistory>[] = [
        { Header: 'Date', accessor: 'date' },
        {
            Header: 'Procedure Codes', accessor: 'procedure_codes', Cell: ({ value }) => value == null ? '' : value.join(", ")
        }
    ]
    const section: Section<TreatmentHistory> = {
        title: 'Treatment History',
        columns: columns,
        rows: historyObjects,
    };
    return section;
}
