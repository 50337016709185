import React from 'react';

const SearchIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path d="M14.3153 6.5C14.3153 9.78459 11.4704 12.5 7.89397 12.5C4.31753 12.5 1.47266 9.78459 1.47266 6.5C1.47266 3.21541 4.31753 0.5 7.89397 0.5C11.4704 0.5 14.3153 3.21541 14.3153 6.5Z" stroke="#5E5D5F" />
    <rect width="8.49962" height="1.79743" transform="matrix(0.728945 0.684572 -0.728945 0.684572 12.9316 10)" fill="#5E5D5F" />
  </svg>
);

export default SearchIcon;
