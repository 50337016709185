import React from 'react';
import VideoFile from './Assets/Health Harbor AI Speaking with Insurance Rep (Twitter).mp4';
import './TitleVideo.css';

const TitleVideo: React.FC = () => {
  return (
        <div className='video-container'>
            <video className="video" controls>
                <source src={VideoFile} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
  );
};

export default TitleVideo;
