import React from 'react';
import './HowItWorks.css';
import { ReactComponent as StepOne } from './Assets/StepOne.svg';
import { ReactComponent as StepTwo } from './Assets/StepTwo.svg';
import { ReactComponent as StepThree } from './Assets/StepThree.svg';
import { ReactComponent as StepFour } from './Assets/StepFour.svg';

import { ReactComponent as StepOneSM } from './Assets/StepOneSM.svg';
import { ReactComponent as StepTwoSM } from './Assets/StepTwoSM.svg';
import { ReactComponent as StepThreeSM } from './Assets/StepThreeSM.svg';
import { ReactComponent as StepFourSM } from './Assets/StepFourSM.svg';


const HowItWorks: React.FC = () => {
    return (
        <div className='HowItWorks' id="HowItWorks">
            <div className='Title'>
                How it works
            </div>
            <div className='Steps'>
                <div className='Step'>
                    <div className='Left'>
                        <StepOne className='StepImage' />
                    </div>
                    <div className='Right'>
                        <div className='Number'>1</div>
                        <div className='Subtitle'>Submit request</div>
                        <div className='Subtext'>Input patient information and request eligibility or claims as easily as sending an email.</div>
                    </div>
                </div>
                <StepOneSM className='ImageSM'/>
                <div className='Step'>
                    <div className='Left'>
                        <div className='Number'>2</div>
                        <div className='Subtitle'>We call insurance for you</div>
                        <div className='Subtext'>Our AI calls the insurance company and retrieves the information for you.</div>
                    </div>
                    <div className='Right'>
                        <StepTwo className='StepImage' />
                    </div>
                </div>
                <StepTwoSM className='ImageSM'/>
                <div className='Step'>
                    <div className='Left'>
                        <StepThree className='StepImage' />
                    </div>
                    <div className='Right'>
                        <div className='Number'>3</div>
                        <div className='Subtitle'>View status</div>
                        <div className='Subtext'>Instantly see the status of the request in our dashboard.</div>
                    </div>
                </div>
                <StepThreeSM className='ImageSM'/>
                <div className='Step'>
                    <div className='Left'>
                        <div className='Number'>4</div>
                        <div className='Subtitle'>Print result</div>
                        <div className='Subtext'>Once a request is completed, view and print the full eligibility or claim status.</div>
                    </div>
                    <div className='Right'>
                        <StepFour className='StepImage' />
                    </div>
                </div>
                <StepFourSM className='ImageSM'/>
            </div>
        </div>
    )
}

export default HowItWorks;
