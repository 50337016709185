import React from 'react';

const RequestIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>> = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.76923 19.1638L1.76439 15.6301L5.29992 12.0946L8.83546 15.6301L5.29992 19.1657L1.76923 19.1638Z" stroke="black" />
        <path d="M2.4707 14.9238L13.7844 3.61012L17.3199 7.14565L6.00624 18.4594L2.4707 14.9238Z" fill="#9CC9C9" />
        <path d="M13.7844 3.61012L14.138 3.25657L13.7844 2.90301L13.4309 3.25657L13.7844 3.61012ZM17.3199 7.14565L17.6735 7.49921L18.0271 7.14565L17.6735 6.7921L17.3199 7.14565ZM2.82426 15.2774L14.138 3.96367L13.4309 3.25657L2.11715 14.5703L2.82426 15.2774ZM13.4309 3.96367L16.9664 7.49921L17.6735 6.7921L14.138 3.25657L13.4309 3.96367ZM16.9664 6.7921L5.65268 18.1058L6.35979 18.8129L17.6735 7.49921L16.9664 6.7921Z" fill="black" />
        <path d="M13.7852 3.60938L15.6872 1.70735C16.0777 1.31683 16.7109 1.31683 17.1014 1.70735L19.2227 3.82867C19.6132 4.2192 19.6132 4.85236 19.2227 5.24288L17.3207 7.14491L13.7852 3.60938Z" fill="black" stroke="black" />
    </svg>
);

export default RequestIcon;
