import React from 'react';
import './RequestRow.css';
import Status from 'MVP/Shared/Status';
import ParseTime from 'MVP/Shared/ParseTime';
import fetchRequest from 'store/thunks/fetchRequest';
import { useDispatch } from 'react-redux';
import { REQUEST } from 'reducers';

function RequestRow({ Name, Topic, status, Subject, Time, claimsDOS, id }) {
    const dispatch = useDispatch();
    const claimsDosHtml = claimsDOS ? <div className='RequestDOS'>DOS: {claimsDOS}</div> : null;

    return (

        <div className='RequestRow' >
            <div className='RequestRowWrapper' onClick={() => {
                dispatch({ type: REQUEST.display });
                dispatch(fetchRequest(id));
            }}>
                <div className='RequestName'>{Name}</div>
                <div className='RequestTopic'>{Topic}</div>
                <Status status={status} />
                {claimsDosHtml}
                <div className='RequestSubject'>{Subject}</div>
                <div className='RequestTime'>{ParseTime(Time)[0]}</div>
            </div>

        </div>
    );
}

export default RequestRow;
