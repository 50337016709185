import { ADD_TRANSFER_PHONE_NUMBER_MODAL } from 'reducers';

const initialState = {
    show: false
};

const addTransferPhoneNumberModalReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_TRANSFER_PHONE_NUMBER_MODAL.display:
            return { ...state, show: true };
        case ADD_TRANSFER_PHONE_NUMBER_MODAL.hide:
            return { ...state, show: false };
        default:
            return state;
    }
};

export default addTransferPhoneNumberModalReducer;
