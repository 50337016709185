import React from 'react';
import './FinalCallToAction.css';

interface FinalCallToActionProps {
    openCalendly: () => void;
}

const FinalCallToAction: React.FC<FinalCallToActionProps> = ({ openCalendly }) => {
    return (
        <div className='FinalCallToAction'>
            <div className='FinalCallToAction-Title' id="FinalCallToAction">Never call insurance again.</div>
            <div className='FinalCallToAction-BookDiscoveryCallButton' onClick={openCalendly}>Book discovery call</div>
        </div>
    );
}

export default FinalCallToAction;
