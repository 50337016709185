import React from 'react';
import shamima from './Assets/shamima.png';
import diva from './Assets/diva.png';
import TestimonialCard from './TestimonialCard';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import './Testimonials.css';
import { TestimonialCardProps } from './TestimonialCard';

const Testimonials: React.FC = () => {
    const TestimonialData: Array<TestimonialCardProps> = [
        {
            image: shamima,
            quote: (<>The <strong>speed</strong> and <strong>accuracy</strong> of the results enable our patients to make <strong>informed decisions</strong></>),
            title: (<>Shamima Mather<br/>Director of Clinical Operations<br/>Kivo Health</>)
        },
        {
            image: diva,
            quote: (<>Health Harbor’s <strong>seamless</strong> eligibility verifications enable us to <strong>focus on patient care</strong></>),
            title: (<>Diva Sharma<br/>Co-founder & CEO<br/>Flair Health</>)
        }
    ];

    return (
        <div className='Testimonials'>
            <Swiper
                slidesPerView={1}
                spaceBetween={40}
                navigation={true}
                modules={[Navigation]}
                className="testimonialSwiper"
                breakpoints={{
                    1000: {
                        slidesPerView: 2,
                        spaceBetween: 40
                    }
                }}
            >
                {TestimonialData.map((testimonial, index) => (
                    <SwiperSlide key={index}>
                        <TestimonialCard image={testimonial.image} quote={testimonial.quote} title={testimonial.title}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Testimonials;
