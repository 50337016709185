import React from 'react';
import './Investors.css';
import  YCombinatorLogo from './Assets/YCombinatorLogo.png';
import  Pacific8Logo from './Assets/Pacific8Logo.png';

interface InvestorsProps {
    openPacific8: () => void;
    openYCombinator: () => void;
}

const Investors: React.FC<InvestorsProps> = ({openPacific8, openYCombinator}) => {
    return (
        <div className='Investors'>
            <div className='InvestorTitle'>Backed By</div>
            <div className='InvestorsWrapper'>
                <img src={YCombinatorLogo} alt='YCombinatorLogo' className='InvestorLogo' onClick={openYCombinator}/>
                <img src={Pacific8Logo} alt='Pacific8Logo' className='InvestorLogo' onClick={openPacific8}/>
            </div>
        </div>
    )
}

export default Investors;
