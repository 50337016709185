import React from 'react';
import './ProfileModal.css';
import Signout from '../Assets/Signout.svg';

function ProfileModal({ logout, currentUser }) {

    return (
        <div className='ProfileModal'>
            <div className='ProfileModalAvatar'>
                <div className='ProfileModalProfileInfo'>
                    <img src={currentUser.picture_url} className="ProfileModal-AvatarIcon" alt="Avatar Icon" />
                    <div className='ProfileModal-Text'>
                        <div className='ProfileModal-Name'>{currentUser.first_name + " " + currentUser.last_name}</div>
                        <div className='ProfileModal-Email'>{currentUser.email}</div>
                    </div>
                </div>
            </div>
            <div className='ProfileModalSignout' onClick={() => logout()}>
                <img src={Signout} className="Signout" alt="Signout" />
                Signout
            </div>
        </div>

    );
}

export default ProfileModal;
