import React from 'react';
import parseEligibilitySearch from './LegacyTransforms';
import RequestTable from './RequestTable';
import { COLUMNS_CPT, COLUMNS_MAXIMUMS } from '../../constants';

function RequestFormParser({ json }) {

    const parseJson = (originalJson) => {
        const parsedJson = parseEligibilitySearch(originalJson);
        return formatHTML(parsedJson);
    }

    const formatHTML = (parsedJson) => {
        return (
            parsedJson.map((item) => {
                //Check if there are groups
                if (item[0]["groups"] !== undefined) {
                    if (item[0].groups[0][0].data !== undefined) {
                        // if there is just one level of group, gather all the data under one header and set of columns
                        const groupData = [];
                        item[0].groups.forEach((group) => {
                            groupData.push(group[0].data);
                        });
                        return (<RequestTable title={item[0].title.split('_').join(' ')} data={groupData} columns={item[0].groups[0][0].columns} />)
                    } else {
                        // if there are multiple levels of groups, map the data for each group into a table
                        return item[0].groups.map((group) => {
                            const originalCols = group[1][1][0].columns;
                            const groupColumns = [];
                            originalCols.forEach((key) => {
                                if (key.accessor !== "is_covered") {
                                    let temp = key;
                                    COLUMNS_CPT.forEach((val) => {
                                        if (key.accessor === val.accessor) {
                                            temp = val;
                                        }
                                    })
                                    groupColumns.push(temp);
                                }
                            })
                            return (<RequestTable title={group[1][0][0].procedure_code_group_name} data={group[1][1][0].data} columns={groupColumns} />)
                        });
                    }
                } else {
                    // If there are no groups, check if we need to add $ signs
                    const originalCols = item[0].columns;
                    const groupColumns = [];
                    originalCols.forEach((key) => {
                        let temp = key;
                        COLUMNS_MAXIMUMS.forEach((val) => {
                            if (key.accessor === val.accessor) {
                                temp = val;
                            }
                        })
                        groupColumns.push(temp);
                    })
                    return (<RequestTable title={item[0].title} data={[item[0].data]} columns={groupColumns} />)
                }
            })
        );
    }

    return (
        <>
            {parseJson(json)}
        </>
    );
}

export default RequestFormParser;
