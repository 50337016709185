import React from 'react';
import './NotFound.css';

function NotFound() {
    return (
        <div className='NotFound'>
            <div className='NotFound-Title'>
                Error 404
            </div>
            <div className='NotFound-Subtitle'>
                Page Not Found
            </div>
        </div>
    )
}

export default NotFound;
