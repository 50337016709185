import React from 'react';
import './OnboardingForm.css';
import { HarborInput, HarborSelect } from './../HarborForm';
import { SPECIALTY_OPTIONS } from '../constants';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Mixpanel } from './../Analytics';
import baseApi from '../axios-instance';
import { useNavigate } from 'react-router-dom';
import { OnboardingInfoSchema } from './../MVP/Shared/ValidationSchema';
import { useEffect } from 'react';

function OnboardingForm() {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(OnboardingInfoSchema)
    });

    const navigate = useNavigate();

    const onSubmit = async (data) => {

        await baseApi.post('/onboarding', data, { withCredentials: true }).then(response => {
            Mixpanel.track('Onboarding submitted');
            navigate('/dashboard', { replace: true });
        }).catch(error => {
            console.log(error);
            Mixpanel.track('Error: New Request', { error: error });
            const message = error.response?.data?.detail || error.response?.data;
            alert(message);
        });
    };

    const index = 0;

    useEffect(() => {
        setValue('practices', [{}]);
        setValue(`practices[${index}].providers`, [{}]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className='OnboardingForm'>
            <div className='OnboardingFormTitle'>Hello. Let's get started.</div>
            <form onSubmit={handleSubmit(onSubmit)} className='OnboardingFormWrapper'>
                <div className='OnboardingFormContent'>
                    <div className='OnboardingFormSubtitle'>Tell us a bit about your practice. You can add multiple practices later.</div>
                    <div className='OnboardingFormRow'>
                        <HarborSelect label={"specialty"} text={"Specialty"} register={register} error={errors.specialty} options={SPECIALTY_OPTIONS} />
                        <HarborInput label={`practices[${index}].name`} text={"Practice Name"} register={register} error={errors.practices?.[index]?.name} />
                        <HarborInput label={`practices[${index}].tax_id`} text={"Tax ID"} register={register} error={errors.practices?.[index]?.tax_id} />
                    </div>

                    <div className='OnboardingFormRow'>
                        <HarborInput label={`practices[${index}].address`} text={"Address"} register={register} error={errors.practices?.[index]?.address} />
                        <HarborInput label={`practices[${index}].city`} text={"City"} register={register} error={errors.practices?.[index]?.city} />
                        <HarborInput label={`practices[${index}].state`} text={"State"} register={register} error={errors.practices?.[index]?.state} />
                        <HarborInput label={`practices[${index}].zip`} text={"Zip Code"} register={register} error={errors.practices?.[index]?.zip} />
                    </div>

                    <div className='OnboardingFormSubtitle'>Tell us a bit about your provider. You can add multiple providers later.</div>
                    <div className='OnboardingFormRow'>
                        <HarborInput label={`practices[${index}].providers[${index}].name`} text={"Provider's Name"} register={register} error={errors.practices?.[index]?.providers?.[index]?.name} />
                        <HarborInput label={`practices[${index}].providers[${index}].npi`} text={"NPI"} register={register} error={errors.practices?.[index]?.providers?.[index]?.npi} />
                        <HarborInput label={`practices[${index}].providers[${index}].medicaid_provider_number`} text={"medicaid provider #"} register={register} error={errors.practices?.[index]?.providers?.[index]?.medicaid_provider_number} />
                    </div>

                    <div className='OnboardingFormSubtitle'>Tell us more, if you have these numbers handy.</div>
                    <div className='OnboardingFormRow'>
                        <HarborInput label={`practices[${index}].billing_id`} text={"Billing ID"} register={register} error={errors.practices?.[index]?.billing_id} />
                    </div>

                </div>
                <div className='OnboardingFormNav'>
                    <input type="submit" className='OnboardingNextButton' />
                </div>
            </form>
        </div>
    )
}

export default OnboardingForm;
