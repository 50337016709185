import { createStore, combineReducers, applyMiddleware } from 'redux';
import requestModalReducer from './reducers/requestModalReducer';
import profileModalReducer from './reducers/profileModalReducer';
import showAccountReducer from './reducers/showAccountReducer';
import showRequestReducer from './reducers/showRequestReducer';
import setInboxReducer from './reducers/setInboxReducer';
import showAccountSavedReducer from './reducers/showAccountSavedReducer';
import newOnboardModalReducer from './reducers/newOnboardModalReducer';
import fetchRequestReducer from './reducers/fetchRequestReducer';
import thunk from 'redux-thunk';
import addTransferPhoneNumberModalReducer from './reducers/addTransferPhoneNumberModalReducer';

const rootReducer = combineReducers({
    requestModal: requestModalReducer,
    profileModal: profileModalReducer,
    showAccount: showAccountReducer,
    showRequest: showRequestReducer,
    showAccountSaved: showAccountSavedReducer,
    newOnboardModal: newOnboardModalReducer,
    addTransferPhoneNumberModal: addTransferPhoneNumberModalReducer,
    fetchRequest: fetchRequestReducer,
    setInbox: setInboxReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
