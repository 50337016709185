import { SET_PRACTICE_ID, FETCH_REQUEST, INBOX } from 'reducers';


export const setPracticeID = (practiceID) => ({
    type: SET_PRACTICE_ID,
    payload: practiceID,
});

export const setSearchQuery = (query) => ({
    type: INBOX.search_inbox,
    payload: query,
});

export const requestSent = (requestID) => ({
    type: FETCH_REQUEST.sent,
    payload: requestID,
});

export const requestSuccess = (requestDetails) => ({
    type: FETCH_REQUEST.success,
    payload: requestDetails,
});

export const requestFailure = (error) => ({
    type: FETCH_REQUEST.failure,
    payload: error,
});
