import React, { useState } from 'react';
import './ParseTranscript.css';
import { HarborInput, HarborSelect } from './../HarborForm';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ParseTranscriptSchema } from './../MVP/Shared/ValidationSchema';
import { CODE_COVERAGE } from '../constants';

function ParseTranscript() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(ParseTranscriptSchema)
    });

    const codes = ['D4263', 'D6104', 'D7953', 'D4265', 'D4266', 'D4249', 'D6190', 'D0364', 'D4275', 'D9120',
        'D7951', 'D7952', 'D7961', 'D7210', 'D7220', 'D7230', 'D7240', 'D9310', 'D7286',
        'D3421', 'D3430', 'D6057', 'D6058', 'D6059', 'D6069', 'D6240', 'D4240', 'D7310', 'D7320', 'D4260', 'D4261',
        'D6191', 'D6192', 'D6110', 'D6111'
    ]

    const [finalJson, setFinalJson] = useState("");

    const onSubmit = async (data: any) => {

        const results = `{
            "call_details": {
                "call_end_time": "${data.call_end_time}",
                "representative_name": "${data.representative_name}",
                "reference_number": "${data.reference_number}"
            },
            "plan_information": {
                "is_provider_in_network": "${data.is_provider_in_network}",
                "is_calendar_year_plan": "${data.is_calendar_year}",
                "coordination_of_benefits": "${data.coordination_of_benefits}",
                "fee_schedule": "${data.fee_schedule}"
            },
            "maximums": {
                "individual_deductible": "${data.individual_deductible}",
                "individual_deductible_used": "${data.individual_deductible_used}",
                "family_deductible": "${data.family_deductible}",
                "family_deductible_used": "${data.family_deductible_used}",
                "maximum": "${data.maximum}",
                "accumulation": "${data.accumulation}",
                "separate_implant_max": "${data.separate_implant_max}",
                "separate_perio_max": "${data.separate_perio_max}"
            },
            "extra_dental_info": {
                "non_covered_procedure_fees": "${data.non_covered_procedure_fees}",
                "sinus_lift_medical_denial": "${data.sinus_lift_medical_denial}",
                "limit_on_implants_per_year": "${data.implant_limit}",
                "waiting_periods": "${data.waiting_periods}",
                "missing_tooth_clause": "${data.missing_tooth_clause}"
            },
            "procedure_code_groups": [
                {
                    "procedure_code_group_name": "Perio / Surgical CPT Codes",
                    "procedure_codes": [
                        ${codes.map(
            (value: string) => {
                return (
                    `{
                                        "code": "${value}",
                                        "is_covered": ${data[value].is_covered === "true" ? true : false},
                                        "category": "${data[value].coverage_percentage}",
                                        "allowed_amount": "${data[value].allowed_amount}",
                                        "frequency": "${data[value].frequency}",
                                        "more_info": "${data[value].more_info}"

                                                    }`
                )
            }
        )
            }
                    ]
                }
            ]
        }`

        // Test for valid json
        try {
            JSON.parse(results)
        } catch (error) {
            alert("Error with parsing json: " + error)
        }

        setFinalJson(JSON.stringify(JSON.parse(results)));
    };

    return (
        <div className='ParseTranscriptSplitScreen'>
            {finalJson !== "" &&
                <textarea
                    name="textBox"
                    value={finalJson}
                    rows={400}
                    cols={40}
                    className='ParseTranscriptTextBox'
                />
            }
            <div className='ParseTranscriptOuterFrame'>
                <div className='ParseTranscriptFrame'>
                    <div className='ParseTranscript'>
                        <div className='ParseTranscriptTitle'>Hello. Let's get started.</div>
                        <form onSubmit={handleSubmit(onSubmit)} className='ParseTranscriptWrapper'>
                            <div className='ParseTranscriptContent'>
                                <div className='ParseTranscriptSubtitle'>Fill out the information below. Then click submit to generate the json.</div>
                                <div className='ParseTranscriptSubtitle'>Call Details</div>
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`call_end_time`} text={"Call End Time"} register={register} error={errors.call_end_time} />
                                    <HarborInput label={`representative_name`} text={"Representative Name"} register={register} error={errors.representative_name} />
                                    <HarborInput label={`reference_number`} text={"Reference Number"} register={register} error={errors.reference_number} />
                                </div>

                                <div className='ParseTranscriptSubtitle'>Plan Details</div>
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`is_provider_in_network`} text={"Is the provider in network?"} register={register} error={errors.is_provider_in_network} />
                                    <HarborInput label={`coordination_of_benefits`} text={"What is the coordination of benefits?"} register={register} error={errors.coordination_of_benefits} />
                                    <HarborInput label={`fee_schedule`} text={"What is the name of the fee schedule?"} register={register} error={errors.fee_schedule} />
                                </div>

                                <div className='ParseTranscriptSubtitle'>Maximums</div>
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`is_calendar_year`} text={"Is the plan calendar year?"} register={register} error={errors.is_calendar_year} />
                                    <HarborInput label={`individual_deductible`} text={"What is the individual deductible?"} register={register} error={errors.individual_deductible} />
                                    <HarborInput label={`individual_deductible_used`} text={"How much is used of the deductible?"} register={register} error={errors.individual_deductible_used} />
                                </div>
                                <div className='ParseTranscriptSubtitle' />
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`family_deductible`} text={"What is the family deductible?"} register={register} error={errors.family_deductible} />
                                    <HarborInput label={`family_deductible_used`} text={"How much is used of the deductible?"} register={register} error={errors.family_deductible_used} />
                                </div>
                                <div className='ParseTranscriptSubtitle' />
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`separate_perio_max`} text={"Is there a separate Perio Max?"} register={register} error={errors.separate_perio_max} />
                                    <HarborInput label={`separate_implant_max`} text={"Is there a separate Implant Max?"} register={register} error={errors.separate_implant_max} />
                                </div>
                                <div className='ParseTranscriptSubtitle' />
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`maximum`} text={"What is the plan maximum?"} register={register} error={errors.maximum} />
                                    <HarborInput label={`accumulation`} text={"How much of the plan maximum is used?"} register={register} error={errors.accumulation} />
                                </div>

                                <div className='ParseTranscriptSubtitle'>Extra Dental Info</div>
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`waiting_periods`} text={"Is there a waiting period?"} register={register} error={errors.waiting_periods} />
                                    <HarborInput label={`missing_tooth_clause`} text={"Is there a missing tooth clause?"} register={register} error={errors.missing_tooth_clause} />
                                </div>
                                <div className='ParseTranscriptSubtitle' />
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`non_covered_procedure_fees`} text={"Can we charge UCR fees for non-covered fees?"} register={register} error={errors.non_covered_procedure_fees} />
                                    <HarborInput label={`sinus_lift_medical_denial`} text={"Is medical denial required for sinus lift coverage?"} register={register} error={errors.sinus_lift_medical_denial} />
                                </div>
                                <div className='ParseTranscriptSubtitle' />
                                <div className='ParseTranscriptRow'>
                                    <HarborInput label={`implant_limit`} text={"Is there a limit on the number of implants per year?"} register={register} error={errors.implant_limit} />
                                </div>

                                <div className='ParseTranscriptSubtitle'>Codes</div>
                                {codes.map((value: string, index: number) => {
                                    return (
                                        <>
                                            <div className='ParseTranscriptSubtitle'>{value}</div>
                                            <div className='ParseTranscriptRow'>
                                                <HarborSelect label={`${value}.is_covered`} text={"Is Covered"} register={register} error={errors.codes?.[index]?.is_covered} options={CODE_COVERAGE} />
                                                <HarborInput label={`${value}.coverage_percentage`} text={"Coverage Percentage"} register={register} error={errors.codes?.[index]?.coverage_percentage} />
                                                <HarborInput label={`${value}.allowed_amount`} text={"Allowed Amount"} register={register} error={errors.codes?.[index]?.allowed_amount} />
                                                <HarborInput label={`${value}.frequency`} text={"Frequency"} register={register} error={errors.codes?.[index]?.frequency} />
                                                <HarborInput label={`${value}.more_info`} text={"More Info"} register={register} error={errors.codes?.[index]?.more_info} />
                                            </div>
                                        </>
                                    )
                                })}
                                <div className='ParseTranscriptNav'>
                                    <input type="submit" className='ParseTranscriptNextButton' />
                                </div>


                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    );

}

export default ParseTranscript;
