import React from 'react';
import './JsonToTable.css';
import { useForm } from 'react-hook-form';

export function FormatAsTable() {
    const {
        register,
    } = useForm();

    return (
        <>
            <form action="/format_as_table" method="post" encType="application/x-www-form-urlencoded">
                <div className="JsonToTableWrapper">
                    <textarea id="user_inputted_json" className="JsonToTable-Input" {...register("user_inputted_json")} rows={8} />
                    <div className="JsonToTable-Button">
                        <button type="submit"> Submit </button>
                    </div>
                </div>
            </form>
        </>

    );
}
