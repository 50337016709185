import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import './RequestTable.css';

function RequestTable({ title, data, columns }) {
    // Create a table instance using react-table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        usePagination
    );
    var rows_html;
    if (rows.length === 0) {
        rows_html = <tr><td colSpan={columns.length} style={{ textAlign: 'center' }}>None</td></tr>;
    } else {
        rows_html = rows.map(row => {
            prepareRow(row);
            return (
                <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                        var cellProps = cell.getCellProps(
                            {
                                style: {
                                    width: cell.column.width,
                                },
                            }
                        );
                        let row_override = cell.row.original.row_override;
                        if (row_override != null && cell.value == null) {
                            return (
                                <td {...cellProps}>{row_override}</td>
                            );
                        }
                        return (
                            <td {...cellProps}>{cell.render('Cell')}</td>
                        );
                    })}
                </tr>
            );
        });
    }



    return (
        <div>
            <div className='RequestTable-Title'>{title}</div>
            <table {...getTableProps()} className="table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps({
                                    style: { width: column.width },
                                })}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows_html}
                </tbody>
            </table>
        </div>
    );
}

export default RequestTable;
