import React from 'react';
import './NewOnboardModal.css';
import { HarborInput } from 'HarborForm';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Mixpanel } from 'Analytics';
import baseApi from 'axios-instance';
import { AddTransferPhoneNumberSchema } from 'MVP/Shared/ValidationSchema';
import { REQUESTMODAL, ADD_TRANSFER_PHONE_NUMBER_MODAL } from 'reducers';
import { useDispatch } from 'react-redux';
import Cancel from '../Assets/NewRequest/Cancel.svg';

interface AddTransferPhoneNumberModalProps {
    refreshProfileInfo: () => void;
}

interface NewTransferPhoneNumberForm {
    transfer_to_rep_name: string;
    transfer_to_rep_number: string;
}


function AddTransferPhoneNumberModal({ refreshProfileInfo }: AddTransferPhoneNumberModalProps) {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(AddTransferPhoneNumberSchema)
    });

    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch({ type: ADD_TRANSFER_PHONE_NUMBER_MODAL.hide });
        dispatch({ type: REQUESTMODAL.display });
    }

    const onSubmit = async (data: NewTransferPhoneNumberForm) => {
        await baseApi.post('/dashboard/add_transfer_phone_number', data, { withCredentials: true }).then(response => {
            Mixpanel.track('New transfer phone number added');
            refreshProfileInfo();
            closeModal();
        }).catch(error => {
            console.log(error);
            Mixpanel.track('Error: New transfer phone number added', { error: error });
            const message = error.response?.data?.detail || error.response?.data;
            alert(message);
        });
    };

    // TODO: rename css and classes for modal to be more generic.
    return (
        <div className='NewOnboardModal'>
            <div className='NewOnboardModal-Header'>
                <div className='NewOnboardModal-HeaderTitle'>Have our AI Transfer a Call to You</div>
                <button className='nakedButton' onClick={closeModal}><img src={Cancel} className='CancelIcon' alt="Cancel Icon" /></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='NewOnboardModal-FormWrapper'>
                <div className='NewOnboardModal-FormContent'>
                    <div className='NewOnboardModal-FormSubtitle'>Instead of gathering the information itself, our AI will transfer the call to you once we get a human on the line. Please tell us your name and your phone number (not the patient!). This will be saved so you can select it directly in the future. </div>
                    <div className='NewOnboardModal-FormRow'>
                        <HarborInput label={`transfer_to_rep_name`} text={"Name"} register={register} error={errors.transfer_to_rep_name} />
                        <HarborInput label={`transfer_to_rep_number`} text={"Phone Number"} register={register} error={errors.transfer_to_rep_number} />
                    </div>
                </div>
                <div className='NewOnboardModal-FormNav'>
                    <input type="submit" className='NewOnboardModal-NextButton' />
                </div>
            </form>
        </div>
    )
}

export default AddTransferPhoneNumberModal;
