export const ParseTime = (input: string) => {
    const event = new Date(input);
    const month = event.toLocaleString('en-US', { month: 'short' }) + " " + event.toLocaleString('en-US', { day: '2-digit' });
    const year = event.toLocaleString('en-US', { year: 'numeric' });
    const time = event.toLocaleString('en-US', { hour: '2-digit', minute: 'numeric' });


    return [month, year, time];
};

export default ParseTime;
