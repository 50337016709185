import React from 'react';
import './Status.css';
import { STATUS } from 'constants';

function Status({ status }) {

  return (
    <div className={"Status " + STATUS[status]}>
      {STATUS[status]}
    </div>

  );
}

export default Status;
