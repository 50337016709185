import MVP from './MVP/MVP';
import Site from './Site/Site';
import Onboarding from './Onboarding/Onboarding';
import ParseTranscript from './ParseTranscript/ParseTranscript';
import NotFound from './NotFound/NotFound';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from 'react';
import { FormatAsTable } from 'MVP/Formatter/FormatAsTable';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Site />} />
        <Route path="/dashboard" element={<MVP />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/parse_transcript" element={<ParseTranscript />} />
        <Route path="/format_as_table" element={<FormatAsTable />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
