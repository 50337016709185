function parseEligibilitySearch(obj) {
    const result = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (key === "procedure_code_groups") {
                result.push([{ title: key, groups: unravelMultiGroup(value) }]);
            } else if (value instanceof Array) {
                result.push([{ title: key, groups: unravelSingleGroup(value) }]);
            } else {
                result.push([{ title: key.split('_').join(' '), data: value, columns: generateColumns(value) }]);
            }
        }
    }
    return result;
}

function unravelMultiGroup(obj) {
    const result = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value instanceof Array) {
                // Check if code is not covered
                let temp = value
                for (const row in value) {
                    for (const subitem in value[row]) {
                        if (subitem !== "is_covered" && subitem !== "code") {
                            if (value[row]["is_covered"] === false) {
                                temp[row][subitem] = "N/A";
                            }
                        }
                    }
                }
                result.push([{ title: key.split('_').join(' '), data: temp, columns: generateColumns(temp[0]) }]);
            } else {
                if (key === "procedure_code_group_name") {
                    result.push([{ procedure_code_group_name: value }]);
                } else {
                    result.push([key, unravelMultiGroup(value)]);
                }
            }
        }
    }
    return result;
}

function unravelSingleGroup(obj) {
    const result = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            result.push([{ title: key.split('_').join(' '), data: value, columns: generateColumns(value) }]);
        }
    }
    return result;
}

function generateColumns(obj) {
    const result = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            result.push({ Header: key.split('_').join(' '), accessor: key })
        }
    }
    return result;
}

export default parseEligibilitySearch;
