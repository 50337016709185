import './Request.css';
import RequestFooter from './RequestFooter';
import RequestHeader from "./RequestHeader";
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fetchRequest from 'store/thunks/fetchRequest'
import RequestForm from './RequestForm';
import ParseTime from '../Shared/ParseTime';

const Request = ({ requestDetails, loading, error, fetchRequest, requestID }) => {
    useEffect(() => {
        if (requestID) {
            fetchRequest(requestID);
        }
    }, [fetchRequest, requestID]);

    const showPrintButton = requestDetails?.inquiry_type === "ELIGIBILITY_SEARCH" && requestDetails?.inquiry_status === "SUCCESS";

    return (
        <>
            <div className='Request'>
                <RequestHeader inquiryId={requestID} showPrintButton={showPrintButton} />
                <div className='Request-Body'>
                    {loading ? <div className='Request-Loading'>Loading...</div> :
                        <>
                            {requestDetails && !error &&
                                <RequestForm requestDetails={requestDetails} patientName={requestDetails?.patient_name} subject={requestDetails?.inquiry_summary} topic={requestDetails?.inquiry_type} status={requestDetails?.inquiry_status} time={ParseTime(requestDetails?.inquiry_creation_ts)} showRequestID={requestID} />
                            }
                        </>
                    }
                </div>
                <RequestFooter />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    loading: state.fetchRequest.loading,
    requestDetails: state.fetchRequest.requestDetails,
    requestError: state.fetchRequest.requestError,
    requestID: state.fetchRequest.requestID
});

const mapDispatchToProps = {
    fetchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Request);
