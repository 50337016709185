export const PROFILEMODAL = {
    display: 'DISPLAY_PROFILEMODAL',
    hide: 'HIDE_PROFILEMODAL',
    toggle: 'TOGGLE_PROFILEMODAL',
};

export const REQUESTMODAL = {
    display: 'DISPLAY_REQUESTMODAL',
    hide: 'HIDE_REQUESTMODAL',
};

export const NEWONBOARDMODAL = {
    display: 'DISPLAY_NEWONBOARDMODAL',
    hide: 'HIDE_NEWONBOARDMODAL',
};


export const ADD_TRANSFER_PHONE_NUMBER_MODAL = {
    display: 'DISPLAY_MODAL__ADD_TRANSFER_PHONE_NUMBER',
    hide: 'HIDE_MODAL__ADD_TRANSFER_PHONE_NUMBER',
};

export const ACCOUNT = {
    display: 'DISPLAY_ACCOUNT',
    hide: 'HIDE_ACCOUNT',
};

export const REQUEST = {
    display: 'DISPLAY_REQUEST',
    hide: 'HIDE_REQUEST',
};

export const INBOX = {
    current_user_inbox: 'CURRENT_USER_INBOX',
    team_inbox: 'TEAM_INBOX',
    search_inbox: 'SEARCH_INBOX',
};

export const FETCH_REQUEST = {
    sent: 'FETCH_REQUEST_SENT',
    success: 'FETCH_REQUEST_SUCCESS',
    failure: 'FETCH_REQUEST_FAILURE',
};

export const SPECIALTY = {
    dental: 'DENTAL',
    medical: 'MEDICAL',
    mental_health: 'MENTAL_HEALTH',
    medical_and_dental: 'MEDICAL_AND_DENTAL',
};

export const ACCOUNTSAVED = {
    display: 'DISPLAY_ACCOUNTSAVED',
    hide: 'HIDE_ACCOUNTSAVED',
};

export const SET_PRACTICE_ID = 'SET_PRACTICE_ID';
