import { BenefitsDetails, CallDetails, Maximums, PlanInformation, ProcedureCodeInfo } from './EligibilityQueryResults';
import { Column } from 'react-table';
import { constantNameCaseToTitle, formatCell } from "utils";
import { addDollarSign, insertCheckbox, addPercentSign } from 'utils';


// TODO(alan): need to deprecate and consolidate a lot of these. We can infer the columns from the type properties without rewriting all of this boilerplate.

const CALL_DETAILS: Array<Column<CallDetails>> = [
    { Header: 'Call End Time', accessor: 'call_end_time' },
    { Header: 'Representative', accessor: 'representative_name' },
    { Header: 'Reference Number', accessor: 'reference_number' },
    { Header: 'Call Recording URL', accessor: 'call_recording_url' }
];

var MAXIMUMS: Array<Column<Maximums>> = [
    { Header: 'Individual Deductible', accessor: 'individual_deductible', Cell: ({ value }) => formatCell('individual_deductible', value) },
    { Header: 'Individual Deductible Met', accessor: 'individual_deductible_used', Cell: ({ value }) => formatCell('individual_deductible_used', value) },
    { Header: 'Individual Out of Pocket Max', accessor: 'individual_out_of_pocket_maximum', Cell: ({ value }) => formatCell('individual_out_of_pocket_maximum', value) },
    { Header: 'Individual Out of Pocket Met', accessor: 'individual_out_of_pocket_maximum_used', Cell: ({ value }) => formatCell('individual_out_of_pocket_maximum_used', value) },
    { Header: 'Family Deductible', accessor: 'family_deductible', Cell: ({ value }) => formatCell('family_deductible', value) },
    { Header: 'Family Deductible Met', accessor: 'family_deductible_used', Cell: ({ value }) => formatCell('family_deductible_used', value) },
    { Header: 'Family Out of Pocket Max', accessor: 'family_out_of_pocket_maximum', Cell: ({ value }) => formatCell('family_out_of_pocket_maximum', value) },
    { Header: 'Family Out of Pocket Met', accessor: 'family_out_of_pocket_maximum_used', Cell: ({ value }) => formatCell('family_out_of_pocket_maximum_used', value) },
];

const PLAN_INFORMATION: Array<Column<PlanInformation>> = [
    { Header: 'Is Active', accessor: 'is_active', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Plan Type', accessor: 'plan_type' },
    { Header: 'Effective Date', accessor: 'effective_date' },
    { Header: 'Termination Date', accessor: 'termination_date' },
    { Header: 'Is Provider In Network', accessor: 'is_provider_in_network', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Has Out of State Coverage', accessor: 'has_out_of_state_coverage', width: 110, Cell: ({ value }) => insertCheckbox(value) }
];

export const PLACEHOLDER_COLUMN: Column<BenefitsDetails> = {
    Header: 'Coverage',
    accessor: 'category', // an arbitrary accessor value
    width: 110,
}


// TODO(alan) consolidate these types.
export const FULL_BENEFIT_DETAILS: Array<Column<BenefitsDetails & ProcedureCodeInfo> & { className?: string }> = [
    { Header: '', accessor: 'procedure_code', className: 'left-column', width: 70 },
    { Header: 'Telehealth Covered', accessor: 'is_telehealth_covered', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Prior Auth Required', accessor: 'is_prior_auth_required', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Deductible Waived', accessor: 'is_deductible_waived', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Category', accessor: 'category', Cell: ({ value }) => constantNameCaseToTitle(value as string) },
    { Header: 'Copay Amount', accessor: 'copay_amount', Cell: ({ value }) => addDollarSign(value) },
    { Header: 'Patient Coinsurance Percentage', accessor: 'coinsurance_percentage', Cell: ({ value }) => addPercentSign(value) },
    { Header: 'Coverage Percentage', accessor: 'coverage_percentage', Cell: ({ value }) => addPercentSign(value) },
    { Header: 'Allowed Fee', accessor: 'allowed_fee', width: 110, Cell: ({ value }) => addDollarSign(value) },
    { Header: 'Frequency', accessor: 'frequency_limitations', width: 230 },
    { Header: 'Limitations', accessor: 'limitations', width: 230 },
    { Header: 'Age Limit', accessor: 'age_limit' },
    { Header: 'Downgraded', accessor: 'is_downgraded', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Downgraded Info', accessor: 'downgraded_info' },
    { Header: 'Prior Auth Info', accessor: 'prior_auth_info' },
    { Header: 'More Info', accessor: 'more_info' }]

export const FULL_CATEGORY_DETAILS: Array<Column<BenefitsDetails> & { className?: string }> = [
    { Header: 'Category', accessor: 'category', className: 'left-column', width: 70, Cell: ({ value }) => constantNameCaseToTitle(value as string) },
    { Header: 'Prior Auth Required', accessor: 'is_prior_auth_required', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Deductible Waived', accessor: 'is_deductible_waived', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Copay Amount', accessor: 'copay_amount', Cell: ({ value }) => addDollarSign(value) },
    { Header: 'Patient Coinsurance Percentage', accessor: 'coinsurance_percentage', Cell: ({ value }) => addPercentSign(value) },
    { Header: 'Coverage Percentage', accessor: 'coverage_percentage', Cell: ({ value }) => addPercentSign(value) },
    { Header: 'Allowed Fee', accessor: 'allowed_fee', width: 110, Cell: ({ value }) => addDollarSign(value) },
    { Header: 'Frequency Limitations', accessor: 'frequency_limitations' },
    { Header: 'Age Limit', accessor: 'age_limit' },
    { Header: 'Downgraded', accessor: 'is_downgraded', width: 110, Cell: ({ value }) => insertCheckbox(value) },
    { Header: 'Downgraded Info', accessor: 'downgraded_info' },
    { Header: 'Prior Auth Info', accessor: 'prior_auth_info' },
    { Header: 'More Info', accessor: 'more_info' }]

const EligibilityQueryResultsColumns = {
    call_details: CALL_DETAILS,
    maximums: MAXIMUMS,
    plan_information: PLAN_INFORMATION,
    procedure_codes: FULL_BENEFIT_DETAILS,
}

export default EligibilityQueryResultsColumns;
