import React from 'react';

const InboxIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>> = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="12" height="12" rx="1" stroke="black" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 10C8.10457 10 9 9.10457 9 8H13V13H1V8H5C5 9.10457 5.89543 10 7 10Z" fill="black" />
    </svg>
);

export default InboxIcon;
