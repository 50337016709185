import './AccountSideBar.css';
import React from 'react';

function AccountSideBar({ options, selected, setSelected }) {

    return (
        <div className='AccountSideBar'>
            {options.map((item) => (
                <div className={selected === item.title ? 'AccountSideBar-Option-Selected' : 'AccountSideBar-Option-Unselected'} onClick={() => setSelected(item.title)}>
                    <div className='AccountSideBar-Option-Text'>
                        {item.title}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AccountSideBar;
