import { ACCOUNT } from 'reducers';

const initialState = {
    show: false,
};

const showAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT.display:
            return { ...state, show: true };
        case ACCOUNT.hide:
            return { ...state, show: false };
        default:
            return state;
    }
};

export default showAccountReducer;
