import { useState } from 'react';
import './Account.css';
import AccountInfo from './AccountInfo';
import AccountSideBar from './AccountSideBar';
import React from 'react';

function Account({ refreshProfileInfo }) {
    const options = [{ title: 'Practice Info' }]
    const [selected, setSelected] = useState(options[0].title)

    return (
        <div className='Account'>
            <AccountSideBar options={options} selected={selected} setSelected={setSelected} />
            <AccountInfo refreshProfileInfo={refreshProfileInfo} />
        </div>
    );
}

export default Account;
