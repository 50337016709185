import React from 'react';
import './Sidebar.css';
import SidebarOption from "./SidebarOption";
import RequestIcon from 'MVP/Assets/RequestIcon';
import Inbox from 'MVP/Assets/InboxIcon';
import { useDispatch } from 'react-redux';
import { REQUESTMODAL, INBOX } from 'reducers';
import { useSelector } from 'react-redux';

interface SidebarProps {
    inboxCount: number;
}

function Sidebar({ inboxCount }: SidebarProps) {
    const dispatch = useDispatch();
    const setInbox = useSelector((state: any) => state.setInbox.display);

    const handleUserInboxClick = () => {
        dispatch({ type: INBOX.current_user_inbox })
    };

    const handleTeamInboxClick = () => {
        dispatch({ type: INBOX.team_inbox })
    };

    return (
        <div className='Sidebar'>
            <button className='RequestButton' onClick={() => dispatch({ type: REQUESTMODAL.display })}>
                <div className="RequestIcon" >
                    <RequestIcon />
                </div>
                <div className='RequestTitle'>Request</div>
            </button>
            <SidebarOption Icon={Inbox} name={"My Inbox"} number={inboxCount} active={setInbox === INBOX.current_user_inbox ? true : false} onClick={handleUserInboxClick} />
            <SidebarOption Icon={Inbox} name={"Team Inbox"} number={inboxCount} active={setInbox === INBOX.team_inbox ? true : false} onClick={handleTeamInboxClick} />
        </div >
    );
}

export default Sidebar;
