import React from 'react';
import './SiteHeader.css';
import { baseURL } from '../axios-instance';
import { ReactComponent as Logo } from './Assets/Logo.svg';

interface SiteHeaderProps {
    openCalendly: () => void;
}

const SiteHeader: React.FC<SiteHeaderProps> = ({ openCalendly }) => {
    return (
        <div className='SiteHeader'>
            <div className='SiteHeader-LeftWrapper'>
                <Logo className='SiteHeader-Logo-Icon' />
                <div className='SiteHeader-Logo-Text'>Health Harbor</div>
            </div>
            <div className='SiteHeader-RightWrapper'>
                <a className='SiteHeader-LoginButton'
                    href={baseURL + "/login"}
                    rel="noreferrer"
                >Sign In</a>
                <div className='SiteHeader-DemoButton' onClick={openCalendly}>Book Demo</div>
            </div>
        </div>
    )
}

export default SiteHeader;
