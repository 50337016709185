import React from 'react';
import './Security.css';
import VantaHIPAA from './Assets/VantaHIPAA.png';
import Soc from './Assets/Soc.png';

interface SecurityProps {
    openCalendly: () => void;
}

const Security: React.FC<SecurityProps> = ({ openCalendly }) => {
    return (
        <div className='Security'>
            <div className='Title' id="Security">Compliance</div>
            <div className='SecuritySubtitle'>We are fully HIPAA and SOC 2 compliant. We use Vanta to monitor our technical infrastructure and follow best practices to maintain compliance.</div>
            <div>
                <img className='SecurityIcon' src={Soc} alt="Soc 2 Icon" /><img className='SecurityIcon' src={VantaHIPAA} alt="Vanta HIPAA Icon" />
            </div>
            <div className='LearnMoreButton' onClick={openCalendly}>Learn More</div>
        </div>
    )
}

export default Security;
