import { NEWONBOARDMODAL, SET_PRACTICE_ID } from 'reducers';

const initialState = {
    show: false,
    practice_id: '',
};

const newOnboardModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case NEWONBOARDMODAL.display:
            return { ...state, show: true };
        case NEWONBOARDMODAL.hide:
            return { ...state, show: false };
        case SET_PRACTICE_ID:
            return { ...state, practice_id: action.payload };
        default:
            return state;
    }
};

export default newOnboardModalReducer;
