import React from 'react';
import './MVP.css';
import MVPHeader from './MVPHeader';
import Sidebar from './Sidebar/Sidebar';
import RequestList from './RequestList/RequestList';
import Request from './Request/Request';
import RequestModal from './Modals/RequestModal';
import ProfileModal from './Modals/ProfileModal';
import Account from './Account/Account';
import { useState, useEffect } from 'react';
// @ts-ignore
import { useIdle } from "@uidotdev/usehooks";
import baseApi from '../axios-instance';
import { useNavigate } from 'react-router-dom';
import IDLE_MS from 'constants';
import { DEBUG_EMAILS } from '../constants';
import { Mixpanel } from '../Analytics';
import { useSelector } from 'react-redux';
import NewOnboardModal from './Modals/NewOnboardModal';
import { RequestInfo } from './Request/RequestInfo';
import { Practice, ProfileInfo, Provider, UserTransferPhoneNumber } from './Request/ProfileInfo';
import { INBOX } from 'reducers';
import AddTransferPhoneNumberModal from './Modals/AddTransferPhoneNumberModal';


function MVP() {
    // TODO(nathan): Add class to represent state instead of using any types.
    const showRequestModal = useSelector((state: any) => state.requestModal.show);
    const showProfileModal = useSelector((state: any) => state.profileModal.show);
    const showNewOnboardModal = useSelector((state: any) => state.newOnboardModal.show);
    const showAddTransferPhoneNumberModal = useSelector((state: any) => state.addTransferPhoneNumberModal.show);
    const showAccount = useSelector((state: any) => state.showAccount.show);
    const showRequest = useSelector((state: any) => state.showRequest.show);

    const [inboxCount, setInboxCount] = useState(0);
    const [currentUser, setCurrentUser] = useState({ "email": '', "first_name": '', "last_name": '', "picture_url": '', "id": '', "team_id": '' });
    const [requestRows, setRequestRows] = useState([]);
    const [debugUser, setDebugUser] = useState(false);
    const [lastRequest, setLastRequest] = useState<RequestInfo>()
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>();

    const navigate = useNavigate();

    const logout = () => {
        baseApi.get('/logout').then(response => {
            navigate('/', { replace: true });
        }).catch(error => {
            console.log(error);
        })
    };

    useEffect(() => {
        if (currentUser.id === '') {
            baseApi.get('/dashboard/current-user').then(response => {
                setCurrentUser({ "email": response.data.email, "first_name": response.data.first_name, "last_name": response.data.last_name, "picture_url": response.data.picture_url, "id": response.data.id, "team_id": response.data.team_id });

                if (DEBUG_EMAILS.includes(response.data.email)) {
                    setDebugUser(true);
                }
                Mixpanel.identify(response.data.id);
                Mixpanel.people.set({ $first_name: response.data.first_name, $last_name: response.data.last_name, $email: response.data.email });
            }).catch(error => {
                if (error === '401 Unauthorized') {
                    navigate('/', { replace: true });
                }
                console.log(error);
                Mixpanel.track('Error: Current User', { error: error });
                logout();
            })
        }
    });



    const idle = useIdle(IDLE_MS);



    useEffect(() => {
        if (idle) {
            if (debugUser) {
                console.log('Debug user detected. Bypassing idle logout.');
                return;
            }
            Mixpanel.track('Idle Logout', currentUser);
            logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idle]);

    const reloadPage = async (inboxType: string, query: string) => {
        if (inboxType === INBOX.search_inbox) {
            if (query === '' || query === undefined) return;
            const response = await baseApi.get('/dashboard/search', { params: { query: query }, withCredentials: true });
            try {
                if (JSON.stringify(requestRows) !== JSON.stringify(response.data)) {
                    setRequestRows(response.data);
                    setInboxCount(response.data.search_results);
                }
            } catch (error) {
                console.log(error);
                Mixpanel.track('Error: Reload Page', { error: error });
            }
            return;
        }

        let dataRoute = '';

        if (inboxType === INBOX.current_user_inbox) dataRoute = '/dashboard/data/current_user';
        if (inboxType === INBOX.team_inbox) dataRoute = '/dashboard/data';

        try {
            const response = await baseApi.get(dataRoute, { withCredentials: true });
            if (JSON.stringify(requestRows) !== JSON.stringify(response.data)) {
                setRequestRows(response.data);
                setInboxCount(response.data.length);
            }
        } catch (error) {
            console.log(error);
            Mixpanel.track('Error: Reload Page', { error: error });
        }
    };


    function refreshLastRequest() {
        baseApi.get("/dashboard/latest_created").then(response => {
            // If last request didn't have a provider or a practice, don't prefill any information.
            if (response.data.tax_id === (null || "")) return;
            if (response.data.npi === (null || "")) return;
            // TODO(alan): Verification of result from backend
            var last_request: RequestInfo = { 'type': response.data.inquiry_type, 'insurance_name': response.data.insurance_name, 'practice_id': response.data.practice_id, 'provider_id': response.data.provider_id, 'tax_id': response.data.tax_id, 'insurance_in_network': response.data.insurance_in_network, 'npi': response.data.npi, 'eligibility_search_queries': null, 'place_of_service': response.data.place_of_service, 'is_specialist': response.data.is_specialist };
            if (response.data.inquiry_type === 'ELIGIBILITY_SEARCH') {
                last_request['eligibility_search_queries'] = response.data.eligibility_search_queries;
            }
            setLastRequest(last_request);
        }).catch(error => {
            Mixpanel.track('Error: Retrieving Last Request', { error: error });
            console.log(error);
        });
    }

    function refreshProfileInfo() {
        baseApi.get('/profile').then(response => {
            // TODO(alan): Verification of result from backend
            var practices: Array<Practice> = [];
            for (const practice of response.data.practices) {
                var providers: Array<Provider> = [];
                for (const provider of practice.providers) {
                    providers.push({ 'name': provider.name, 'npi': provider.npi, 'id': provider.id });
                }
                practices.push({ 'name': practice.name, 'tax_id': practice.tax_id, 'providers': providers, 'address': practice.address, 'city': practice.city, 'state': practice.state, 'zip': practice.zip, 'id': practice.id });
            }
            var user_transfer_phone_numbers: Array<UserTransferPhoneNumber> = [];
            for (const phone_number of response.data.phone_numbers) {
                user_transfer_phone_numbers.push({ 'name': phone_number.name, 'phoneNumber': phone_number.phone_number });
            }
            var latest_profile_info: ProfileInfo = { 'specialty': response.data.specialty, 'practices': practices, 'phoneNumbers': user_transfer_phone_numbers };
            setProfileInfo(latest_profile_info);
        }).catch(error => {
            Mixpanel.track('Error: Retrieving Latest Profile Info', { error: error });
            console.log(error);
        });
    }

    // On first load, make sure we populate the lastRequest and any profile information (such as specialty, practices and providers.)
    useEffect(() => { refreshLastRequest(); refreshProfileInfo(); }, []);

    return (
        <>
            {showNewOnboardModal ?
                <><NewOnboardModal refreshProfileInfo={refreshProfileInfo} /></>
                :
                <></>
            }
            {(showAddTransferPhoneNumberModal && debugUser) ?
                <><AddTransferPhoneNumberModal refreshProfileInfo={refreshProfileInfo} /></>
                :
                <></>
            }
            {showRequestModal ?
                <><RequestModal reloadPage={reloadPage} debugUser={debugUser} prefilledInfo={lastRequest} profileInfo={profileInfo} currentUser={currentUser} refreshLastRequest={refreshLastRequest} /></>
                :
                <>
                </>
            }
            <div className='MVPMainPage'>
                <div className='MVPHeader'>
                    <MVPHeader currentUser={currentUser} />
                </div>
                <div className='MVPPageBody'>
                    {showAccount ?
                        <Account refreshProfileInfo={refreshProfileInfo} />
                        :
                        <>
                            <Sidebar inboxCount={inboxCount} />
                            {showRequest ?
                                <Request error={undefined} /> :
                                <RequestList inboxCount={inboxCount} requestRows={requestRows} reloadPage={reloadPage} />
                            }
                        </>
                    }

                    {
                        showProfileModal ?
                            <ProfileModal logout={logout} currentUser={currentUser} />
                            :
                            <></>
                    }
                </div >
            </div >
        </>
    );
}

export default MVP;
