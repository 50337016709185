import { INBOX } from 'reducers';

const initialState = {
    display: "CURRENT_USER_INBOX",
    query: ''
};

const setInboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case INBOX.current_user_inbox:
            return { ...state, display: "CURRENT_USER_INBOX", query: '' };
        case INBOX.team_inbox:
            return { ...state, display: "TEAM_INBOX", query: '' };
        case INBOX.search_inbox:
            return { ...state, display: "SEARCH_INBOX", query: action.payload };
        default:
            return state;
    }
};

export default setInboxReducer;
