import React from 'react';
import './NewOnboardModal.css';
import { HarborInput } from 'HarborForm';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Mixpanel } from 'Analytics';
import baseApi from 'axios-instance';
import { OnboardingInfoSchema } from 'MVP/Shared/ValidationSchema';
import { useState, useEffect } from 'react';
import { REQUESTMODAL, NEWONBOARDMODAL } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import Cancel from '../Assets/NewRequest/Cancel.svg';

function NewOnboardModal({ refreshProfileInfo }) {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(OnboardingInfoSchema)
    });

    const dispatch = useDispatch();

    const curr_practiceID = useSelector(state => state.newOnboardModal.practice_id);

    const onSubmit = async (data) => {
        await baseApi.post('/onboarding', data, { withCredentials: true }).then(response => {
            Mixpanel.track('New Onboarding submitted');
            refreshProfileInfo();
            dispatch({ type: NEWONBOARDMODAL.hide });
            dispatch({ type: REQUESTMODAL.display });
        }).catch(error => {
            console.log(error);
            Mixpanel.track('Error: New Onboarding', { error: error });
            const message = error.response?.data?.detail || error.response?.data;
            alert(message);
        });
    };

    const [practiceIndex, setPracticeIndex] = useState(0);
    const [providerIndex, setProviderIndex] = useState(0);
    const [loadInfo, setLoadInfo] = useState(false);

    useEffect(() => {
        baseApi.get('/profile').then(response => {
            setValue('specialty', response.data.specialty);

            let temp = response.data.practices;
            if (curr_practiceID === 'OTHER') {
                temp.push({
                    "providers": [{}],
                });
                setPracticeIndex(temp.length - 1);
            } else {
                response.data.practices.forEach((val, index) => {
                    if (val.id === curr_practiceID) {
                        setPracticeIndex(index);
                        temp[index].providers.push({});
                        setProviderIndex(temp[index].providers.length - 1);
                    }
                });
            }
            setValue('practices', temp);
            setLoadInfo(true);
        }).catch(error => {
            console.log(error);
            alert(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='NewOnboardModal'>
            <div className='NewOnboardModal-Header'>
                <div className='NewOnboardModal-HeaderTitle'>New Practice</div>
                <button className='nakedButton' onClick={() => dispatch({ type: NEWONBOARDMODAL.hide })}><img src={Cancel} className='CancelIcon' alt="Cancel Icon" /></button>
            </div>
            {!loadInfo && <div className='NewOnboardModal-Loading'>Loading...</div>}
            {loadInfo &&
                <form onSubmit={handleSubmit(onSubmit)} className='NewOnboardModal-FormWrapper'>
                    <div className='NewOnboardModal-FormContent'>
                        <div className='NewOnboardModal-FormSubtitle'>Basic info on your practice.</div>
                        <div className='NewOnboardModal-FormRow'>
                            <HarborInput label={`practices[${practiceIndex}].name`} text={"Practice Name"} register={register} error={errors.practices?.[practiceIndex]?.name} />
                            <HarborInput label={`practices[${practiceIndex}].tax_id`} text={"Tax ID"} register={register} error={errors.practices?.[practiceIndex]?.tax_id} />
                        </div>

                        <div className='NewOnboardModal-FormRow'>
                            <HarborInput label={`practices[${practiceIndex}].address`} text={"Address"} register={register} error={errors.practices?.[practiceIndex]?.address} />
                            <HarborInput label={`practices[${practiceIndex}].city`} text={"City"} register={register} error={errors.practices?.[practiceIndex]?.city} />
                            <HarborInput label={`practices[${practiceIndex}].state`} text={"State"} register={register} error={errors.practices?.[practiceIndex]?.state} />
                            <HarborInput label={`practices[${practiceIndex}].zip`} text={"Zip Code"} register={register} error={errors.practices?.[practiceIndex]?.zip} />
                        </div>

                        <div className='NewOnboardModal-FormSubtitle'>Add a provider.</div>
                        <div className='NewOnboardModal-FormRow'>
                            <HarborInput label={`practices[${practiceIndex}].providers[${providerIndex}].name`} text={"Provider's Name"} register={register} error={errors.practices?.[practiceIndex]?.providers?.[providerIndex]?.name} />
                            <HarborInput label={`practices[${practiceIndex}].providers[${providerIndex}].npi`} text={"NPI"} register={register} error={errors.practices?.[practiceIndex]?.providers?.[providerIndex]?.npi} />
                            <HarborInput label={`practices[${practiceIndex}].providers[${providerIndex}].medicaid_provider_number`} text={"medicaid provider #"} register={register} error={errors.practices?.[practiceIndex]?.providers?.[providerIndex]?.medicaid_provider_number} />
                        </div>

                        <div className='NewOnboardModal-FormSubtitle'>Tell us more, if you have these numbers handy.</div>
                        <div className='NewOnboardModal-FormRow'>
                            <HarborInput label={`practices[${practiceIndex}].billing_id`} text={"Billing ID"} register={register} error={errors.practices?.[practiceIndex]?.billing_id} />
                        </div>

                    </div>
                    <div className='NewOnboardModal-FormNav'>
                        <input type="submit" className='NewOnboardModal-NextButton' />
                    </div>
                </form>}
        </div>
    )

}

export default NewOnboardModal;
