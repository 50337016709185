
import React from 'react';
import { Column } from 'react-table';
import RequestTable from './RequestTable';

export interface TableResults {
    sections: Section<any>[];
}

export interface Section<T extends Object> {
    title: string;
    columns: Array<Column<T>>;
    rows: Array<T & { row_override?: string | null }>;
}

export function displayTableResults(results: TableResults) {
    return (
        <div>
            {results.sections.map((section) => {
                return (
                    <RequestTable title={section.title} data={section.rows} columns={section.columns} />
                );
            })}
        </div>
    );
}
