import { REQUEST } from 'reducers';

const initialState = {
    show: false,
};

const showRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST.display:
            return { ...state, show: true };
        case REQUEST.hide:
            return { ...state, show: false };
        default:
            return state;
    }
};

export default showRequestReducer;
