import React from 'react';
import './RequestFooter.css';
// import Trash from './../Assets/NewRequest/Trash-Icon.svg';
// import ResubmitArrow from './../Assets/Request/ResubmitArrow.svg';

function RequestFooter() {

    return (
        <div className='RequestFooter'>
            {/* <button className='Resubmit'><img src={ResubmitArrow} className="ResubmitArrow" alt="Resubmit Arrow"/>Resubmit</button> */}
            {/* <img src={Trash} className="TrashIcon" alt="Trash Icon"/> */}
        </div>

    );
}

export default RequestFooter;
