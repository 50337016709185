import { REQUESTMODAL } from 'reducers';

const initialState = {
    show: false,
};

const requestModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUESTMODAL.display:
            return { ...state, show: true };
        case REQUESTMODAL.hide:
            return { ...state, show: false };
        default:
            return state;
    }
};

export default requestModalReducer;
