import React from 'react';
import './Onboarding.css';
import OnboardingForm from './OnboardingForm';
import { useState, useEffect } from 'react';
import baseApi from '../axios-instance';
import { useNavigate } from 'react-router-dom';
import IDLE_MS from 'constants';
import { Mixpanel } from '../Analytics';
// @ts-ignore
import { useIdle } from "@uidotdev/usehooks";


function Onboarding() {
    const [currentUser, setCurrentUser] = useState({ "email": '', "first_name": '', "last_name": '', "picture_url": '', "id": '' });

    const navigate = useNavigate();

    const idle = useIdle(IDLE_MS);

    const logout = () => {
        baseApi.get('/logout').then(response => {
            navigate('/', { replace: true });
        }).catch(error => {
            console.log(error);
        })
    };

    useEffect(() => {
        console.log("Try User");
        if (currentUser.id === '') {
            baseApi.get('/dashboard/current-user').then(response => {
                console.log(response);
                setCurrentUser({ "email": response.data.email, "first_name": response.data.first_name, "last_name": response.data.last_name, "picture_url": response.data.picture_url, "id": response.data.id });
                Mixpanel.identify(response.data.id);
                Mixpanel.people.set({ $first_name: response.data.first_name, $last_name: response.data.last_name, $email: response.data.email });
            }).catch(error => {
                console.log(error);
                Mixpanel.track('Error: Current User', { error: error });
                logout();
                // alert(error);
            })
        }
    });

    useEffect(() => {
        if (idle) {
            baseApi.get('/logout').then(response => {
                Mixpanel.track('Idle logout', null)
                navigate('/', { replace: true });
            }).catch(error => {
                console.log(error);
                Mixpanel.track('Error: Idle logout', { error: error })
                alert(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idle])

    return (
        <div className='Onboarding'>
            <div className='OnboardingFrame'>
                <OnboardingForm />
            </div>
        </div>
    );
}

export default Onboarding;
