import React from 'react';
import './TestimonialCard.css';

export interface TestimonialCardProps {
    image: string;
    quote: React.ReactNode;
    title: React.ReactNode;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({image, quote, title}) => {
    return (
        <div className='TestimonialCard'>
            <img src={image} alt={'Testimonial'} className='TestimonialCard-Image'/>
            <div className='TestimonialCard-Box'>
                <div className='TestimonialCard-QuoteWrapper'>
                    <div className='TestimonialCard-QuoteMark'>“</div>
                    <p className='TestimonialCard-Quote'>
                    {quote}
                    </p>
                </div>
                <h3 className='TestimonialCard-Title'>
                    {title}
                </h3>
            </div>
        </div>
    )
}

export default TestimonialCard;
