import React from 'react';
import './SiteTitle.css';
import TitleVideo from './TitleVideo';
import { ReactComponent as HIPAACompliantIcon } from './Assets/HIPAACompliantIcon.svg';

interface SiteTitleProps {
    openCalendly: () => void;
}

const SiteTitle: React.FC<SiteTitleProps> = ({ openCalendly }) => {
    return (
        <div className='SiteTitle'>
            <div className='SiteTitle-LeftWrapper'>
                <div className='SiteTitle-MainTitle'>
                    <div>Never call insurance again.</div>
                </div>
                <div className='SiteTitle-SubTitle'>
                Our AI talks to health insurance reps on your behalf, whether you own a single practice or a billing company.
                </div>
                <div className='SiteTitle-BottomRow'>
                    <div className='SiteTitle-BookDiscoveryCallButton' onClick={openCalendly}>Book a discovery call</div>
                    <div className='SiteTitle-HIPAACompliant'>
                        <HIPAACompliantIcon className='SiteTitle-HIPAACompliant-Icon' />
                        <div className='SiteTitle-HIPAACompliant-Text'>HIPAA Compliant</div>
                    </div>
                </div>
            </div>
            <div className='SiteTitle-RightWrapper'>
                <TitleVideo/>
            </div>
        </div>
    )
}

export default SiteTitle;
