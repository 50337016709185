import { PROFILEMODAL } from 'reducers';

const initialState = {
    show: false,
};

const profileModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROFILEMODAL.display:
            return { ...state, show: true };
        case PROFILEMODAL.hide:
            return { ...state, show: false };
        case PROFILEMODAL.toggle:
            return { ...state, show: !state.show };
        default:
            return state;
    }
};

export default profileModalReducer;
