import React from 'react';
import './SidebarOption.css';

interface SidebarOptionProps {
    Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    name: string;
    number: number;
    active: boolean;
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

function SidebarOption({ Icon, name, number, active, onClick }: SidebarOptionProps) {

    return (
        <div className={`SidebarOption ${active ? 'active' : ''}`} onClick={onClick}>
            <div className='SidebarIcon'>
                <Icon />
            </div>
            <div className='SidebarTitle'>{name}</div>
            <div className='SidebarNumber'>{active ? number : ''}</div>
        </div>

    );
}

export default SidebarOption;
